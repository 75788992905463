<template>
  <div class="">
    <v-btn v-if="currentStep == Step.Details" class="back" text @click="currentStep = Step.Amount">
      <v-img class="mr-2" src="@/assets/images/arrow_yellow.svg" />
      Step Back
    </v-btn>
    <div class="sell-form">
      <v-form ref="form" class="form-card" v-if="currentStep === Step.Amount">
        <h1 class="buy-form-card-title">Sell cryptocurrency</h1>
        <div class="select-section">
          <div>
            <p>Payment cryptocurrency</p>
            <div class="select-section-grid">
              <token-select :items="sellTokens" v-model="form.paymentCurrency"/>
              <v-text-field :error-messages="amountError"
                  @focus="focusAmount"
                  single-line
                  v-model="form.paymentAmount"
                  reverse
                  type="number"
                  class="fw-600 mr-2"
                  solo flat dense>
                  <template v-slot:append-outer>
                  <div class="mt-10px">
                    <info-tooltip type="info" :width="300">
                      Make sure you are sending Binance Smart Chain tokens. You will lose your assets if you use another network.
                    </info-tooltip>
                  </div>
                </template>
              </v-text-field>
            </div>
          </div>
          <div>
            <p>Sell</p>
            <v-select
                :items="sellFiats"
                v-model="form.sellCurrency"
                dense
                solo
                item-value="fiat"
                return-object
                hide-details
                flat
                :menu-props="{contentClass: 'select-menu currency-menu'}"
            >
              <template slot="selection" slot-scope="data">
                <span class="currency-sign">{{ data.item.sign }}</span>
                {{ data.item.fiat }}
              </template>
              <template slot="item" slot-scope="data">
                <span class="currency-sign">{{ data.item.sign }}</span>
                {{ data.item.fiat }}
              </template>
              <template slot="append">
                <img src="@/assets/images/select-arrow.svg" alt="">
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <div class="total-value-row">
            <span class="total-value-row-hint">I will get ≈</span>
            <div class="total-value-row-right">
              <span class="total-value-row-text fw-600">
                <span class="currency-sign">{{ form.sellCurrency.sign }}</span>
                {{ fiat }}
                {{ form.sellCurrency.fiat }}
              </span>
              <v-text-field class="hidden-input" dense hide-details></v-text-field>
            </div>
          </div>
        </div>
        <div>
          <v-btn :disabled="nextStepBtnDisabled"
                 @click="changeStepToDetails"
                 class="submit-btn"
                 depressed>
            Next step
          </v-btn>
        </div>
      </v-form>

      <div v-show="currentStep === Step.Details">
        <ValidationObserver v-slot="{invalid: detailsFormInvalid}">
          <v-form ref="form" class="form-card" @submit="detailsFormSubmit">
            <h1 class="buy-form-card-title">Fill details</h1>
            <div class="detail-form-group">
              <!-- <v-btn class="back" text @click="currentStep = Step.Amount">Step Back</v-btn> -->
              <div>
                <p>Cardholder name</p>
                <ValidationProvider rules="required" name="Cardholder name" v-slot="{errors}">
                  <v-text-field single-line solo flat dense
                                :error-messages="errors"
                                name="cardholder"
                                v-model="detailsForm.cardholder"></v-text-field>
                </ValidationProvider>
              </div>

              <div>
                <p>Date of Birth</p>
                <v-menu :close-on-content-click="false"
                        @v-click-outside="dateOfBitrthPickerIsOpen = false"
                        v-model="dateOfBitrthPickerIsOpen"
                        min-width="290"
                        ref="datepickerMenu">
                  <template v-slot:activator="{on, attrs}">
                    <ValidationObserver ref="datepickerValidationObserver">
                      <ValidationProvider rules="required" name="Card number" v-slot="{errors}" mode="lazy">
                        <v-text-field single-line solo flat dense
                                      :value="formattedDateOfBirth"
                                      :error-messages="errors"
                                      @click="$refs.datepickerValidationObserver.validate()"
                                      readonly
                                      name="dateOfBirth"
                                      v-on="on"
                                      v-bind="attrs"></v-text-field>
                      </ValidationProvider>
                    </ValidationObserver>
                  </template>
                  <v-date-picker v-model="detailsForm.dateOfBirth"
                                 :show-current="false"
                                 ref="picker"
                                 :max="currentISODateMinus18Years"
                                 @input="dateOfBitrthPickerIsOpen = false"
                                 :picker-date="pickerDate"></v-date-picker>
                </v-menu>
              </div>

              <div>
                <p>Card number</p>
                <ValidationProvider rules="required|cardNumber" name="Card number" v-slot="{errors}">
                  <v-text-field single-line solo flat dense
                                :error-messages="errors"
                                name="cardNumber"
                                v-model="detailsForm.cardNumber" v-cardformat:formatCardNumber></v-text-field>
                </ValidationProvider>
              </div>

              <div>
                <p>Card expiration</p>
                <ValidationProvider rules="required|cardExpiry" name="Card expiration" v-slot="{errors}">
                  <v-text-field single-line solo flat dense
                                :error-messages="errors"
                                name="cardExpiry"
                                v-model="detailsForm.cardExpiry" v-cardformat:formatCardExpiry></v-text-field>
                </ValidationProvider>
              </div>

              <div class="phone-number">
                <p>Phone number</p>
                <ValidationProvider :rules="`required|phoneNumber:${$refs.phoneNumberInput ? $refs.phoneNumberInput.phoneObject.isValid : null}`"
                                    name="Phone number" v-slot="{errors}">
                  <vue-tel-input-vuetify
                      single-line flat dense
                      solo
                      placeholder=""
                      label=""
                      :inputOptions="{showDialCode: true, tabIndex: 0}"
                      outlined
                      defaultCountry="RU"
                      name="phoneNumber"
                      :error-messages="errors"
                      ref="phoneNumberInput"
                      type="tel"
                      v-model="detailsForm.phoneNumber"></vue-tel-input-vuetify>
                </ValidationProvider>
              </div>

              <div>
                <p>Email</p>
                <ValidationProvider rules="required|email" name="Email" v-slot="{errors}">
                  <v-text-field single-line solo flat dense
                                name="email"
                                :error-messages="errors"
                                type="email"
                                v-model="detailsForm.email"></v-text-field>
                </ValidationProvider>
              </div>

              <div class="total-value-transfer">
                <span class="total-value-row-text fw-600">
                  <img :src="form.paymentCurrency.image_url" :alt="form.paymentCurrency.token">
                  {{ form.paymentAmount }} {{ form.paymentCurrency.token }}
                </span>
                <img class="arrow-icon" src="@/assets/images/arrow.svg" alt="">
                <span class="total-value-row-text fw-600">
                  <span class="currency-sign">{{ form.sellCurrency.sign }}</span>
                  {{ fiat }}
                  {{ form.sellCurrency.fiat }}
                </span>
              </div>
            </div>

            <div>
              <v-btn :disabled="detailsFormInvalid"
                     class="submit-btn"
                     type="submit"
                     depressed>
                Create the deal
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

      <div class="success-block" v-if="currentStep === Step.Success">
        <div class="form-card attention">
          <div class="attention-icon"></div>
          Attention! You have 3 hours to make the deal. If you make the transaction later, then your money may be lost!
        </div>

        <div class="form-card success-block">
          <div class="success-icon"></div>

          <p class="text-center white--text to-make-deal">
            To make the deal you have to <br>
            transfer <span class="text-primary">{{ form.paymentAmount }} {{ form.paymentCurrency.token }}</span> to wallet below:
          </p>

          <div class="wallet-address">

            <span class="address">{{ address }}</span>
            <v-btn text icon color="primary" class="copy-btn" @click="copyAddressToClipboard">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 72 72">
                <path d="M44.84,10.5H24.9a5,5,0,0,0-5,5V42.8a5,5,0,0,0,5,5H44.84a5,5,0,0,0,5-5V15.46A5,5,0,0,0,44.84,10.5Zm2,32.3a2,2,0,0,1-2,2H24.9a2,2,0,0,1-2-2V15.46a2,2,0,0,1,2-2H44.84a2,2,0,0,1,2,2Z" fill="currentColor"/>
                <path d="M39.07,50.5H19.18a2,2,0,0,1-2-2V21.23a1.5,1.5,0,0,0-3,0V48.51a5,5,0,0,0,5,5H39.07A1.5,1.5,0,0,0,39.07,50.5Z" fill="currentColor"/>
              </svg>
            </v-btn>
            <v-btn outlined color="primary" class="copy-btn--desktop" @click="copyAddressToClipboard">Copy</v-btn>
          </div>
          <QRCanvas :options="qr" />
          <p class="thank-you">
            We are waiting for your transfer<br>
            Thank you!
          </p>

          <p class="ask-questions white--text text-center">
            If you have any questions contact us by writing a letter to <br>
            <a class="text-primary" href="mailto:civilization.capital@gmail.com">civilization.capital@gmail.com</a>
          </p>

          <div class="align-self-stretch">
            <v-btn class="submit-btn"
                   @click="backToNewSell"
                   depressed>
              Back to new Sell
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/combine/npm/qrcanvas@3,npm/qrcanvas-vue@2"></script>
<script>

import { api } from '/src/plugins/axios';
import { currencyItems } from '/src/components/forms/shared';
import { QRCanvas } from 'qrcanvas-vue';
import { mapGetters  } from 'vuex';
import InfoTooltip from '/src/components/InfoTooltip.vue';
import TokenSelect from '/src/components/TokenSelect.vue';
const Step = Object.freeze({Amount: 'Amount', Details: 'Details', Success: 'Success'});

function initialState() {
  const currentDateMinus18Years = new Date();
  currentDateMinus18Years.setFullYear(currentDateMinus18Years.getFullYear() - 18);
  return {
    Step,
    currentStep: Step.Amount,
    currentISODateMinus18Years: currentDateMinus18Years.toISOString(),
    dateOfBitrthPickerIsOpen: false,
    form: {
      paymentAmount: 0,
      paymentCurrency: {},
      sellCurrency: currencyItems[0],
    },
    detailsForm: {
      cardholder: null,
      cardNumber: null,
      cardExpiry: null,
      dateOfBirth: null,
      email: null,
      phoneNumber: null
    },
    pickerDate: '1995-1-1',
    address: null,
    paymentAmountDebounceTimeoutId: null,
    fiat: 0,
    amountError: null,
    addressError: null,
    currencyItems: currencyItems,
    items: [],
    showSuccessAlert: false,
    showErrorAlert: false,
    isProcessing: false,
    qr: {
      data: 'hello',
      cellSize: 8,
    },
  };
}

export default {
  components: {
    QRCanvas,
    InfoTooltip,
    TokenSelect
  },
  name: 'SellForm',
  data() {
    return initialState()
  },
  created() {
    this.items = [];
  },
  computed: {
    nextStepBtnDisabled() {
      return !this.fiat || this.isProcessing;
    },
    formattedDateOfBirth() {
      if (!this.detailsForm.dateOfBirth) {
        return;
      }

      return new Date(this.detailsForm.dateOfBirth).toLocaleString('en-EN', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    },
    ...mapGetters({
        tokens: 'tokens',
        sellFiats: 'sellFiats'
    }),
    sellTokens() {
      return this.tokens.filter(item => item.enable_sell) || [];
    }
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.items = this.tokens;
      if(this.$route.query.token) {
        const token = this.sellTokens.find(item => item.token == this.$route.query.token);
        if(token) this.form.paymentCurrency = token;
        else this.form.paymentCurrency = this.sellTokens[0];
      }
      else {
        this.form.paymentCurrency = this.sellTokens[0];
      }
      this.form.sellCurrency = this.sellFiats[0];
    },
    focusAmount() {
      if (this.form.paymentAmount === 0) {
        this.form.paymentAmount = null;
      }
    },
    async detailsFormSubmit(event) {
      event.preventDefault();

      api.post('change-sale', {
        "token": this.form.paymentCurrency.token,
        "fiat": this.form.sellCurrency.fiat,
        "count": this.form.paymentAmount,
        "cardNumber": this.detailsForm.cardNumber.replace(/ /g, ''),
        "cardExpiry": this.detailsForm.cardExpiry,
        "cardHolder": this.detailsForm.cardholder,
        "cardHolderDOB": this.detailsForm.dateOfBirth,
        "cardHolderMobile": this.detailsForm.phoneNumber,
        "cardHolderEmail": this.detailsForm.email
      }).then(({data}) => {
        this.qr.data = data.address;
        this.address = data.address;
        this.currentStep = Step.Success;
      })
    },
    backToNewSell() {
      Object.assign(this.$data, initialState());
      this.setDefault();
    },
    copyAddressToClipboard() {
      navigator.clipboard.writeText(this.address);
    },
    getCurrency() {
      api.get('available-currency-sale').then(({data}) => {
        this.items = this.items.filter(item => {
          return data.crypto.includes(item.token);
        })
        this.currencyItems = this.currencyItems.filter(item => {
          return data.fiat.includes(item.name);
        })
        this.form.paymentCurrency = this.items[0];
        this.form.sellCurrency = this.currencyItems[0];
      })
    },
    changeStepToDetails() {
      this.form.paymentAmount = +this.form.paymentAmount;
      this.currentStep = Step.Details;
    }
  },
  watch: {
    "detailsForm.cardExpiry": function (val) {
      if(val.length == 7) {
        this.$refs.phoneNumberInput.focus();
      }
    },
    dateOfBitrthPickerIsOpen (val) {
      val && setTimeout(() => (
        this.$refs.picker.activePicker = 'YEAR',
        this.pickerDate = null
      ))
    },
    form: {
      handler(val) {
        this.$router.replace({ query: { token: val.paymentCurrency.token }}).catch(() => {})
        if (val.paymentAmount === null) return;
        if (this.paymentAmountDebounceTimeoutId !== null) {
          clearTimeout(this.paymentAmountDebounceTimeoutId);
        }
        this.paymentAmountDebounceTimeoutId = setTimeout(() => {
          this.paymentAmountDebounceTimeoutId = null;
          this.isProcessing = true;
          api.post('info-sale', {
            "token": val.paymentCurrency.token,
            "fiat": val.sellCurrency.fiat,
            "count": val.paymentAmount
          }).then(({data}) => {
            if (data.error && data.error.access) {
              this.amountError = data.error.access;
              this.fiat = 0;
              return;
            }
            if (data.error && data.error.currency) {
              this.amountError = data.error.currency;
              return;
            }
            this.isProcessing = false;
            this.amountError = null;
            this.addressError = null;
            this.fiat = data.crypto.fiatWithPercent;
          });
        }, 400);
      },
      deep: true
    }
  }
};
</script>


<style lang="scss" scoped>
.form-card {
  --from-card-width: 760px;
  position: relative;
}

.select-section {
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 116px;
  }
}

.field-with-select.v-text-field ::v-deep > .v-input__control > .v-input__slot {
  padding-right: 0;

  > .v-input__append-inner {
    width: 140px;
  }
}

.detail-form-group {
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }

  .v-text-field ::v-deep input[name="cardholder"] {
    text-transform: uppercase;
  }

  .phone-number ::v-deep .country-code {
    margin-right: 10px;
  }

  .total-value-transfer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
    .arrow-icon {
      width: 30px;
      transform: rotate(90deg);
      @media screen and (min-width: 768px) {
        transform: rotate(0);
      }
    }
    .total-value-row-text {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        margin-right: 8px;
      }
    }
  }
}

.success-block .form-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 30px;

  p {
    margin-bottom: 0;
  }

  &.attention {
    flex-direction: row;
    margin-bottom: 25px;
    font-size: 18px;
    padding: 23px 35px;
  }

  .attention-icon {
    position: relative;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 2px solid $primary;
    border-radius: 50%;
    margin-right: 24px;

    &::after {
      content: '!';
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 24px;
      transform: translate(-50%, -50%);
      display: block;
      color: $primary;
    }
  }

  .success-icon {
    position: relative;
    width: 50px;
    height: 50px;
    border: 3px solid $primary;
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      display: block;
      width: 19px;
      height: 13px;
      border-left: 3px solid $primary;
      border-bottom: 3px solid $primary;
    }
  }

  .text-primary {
    color: $primary;
  }

  .to-make-deal {
    font-size: 18px;
    line-height: 27px;
  }

  .wallet-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-dark;
    border-radius: 6px;
    padding: 8px 12px;
    width: 100%;

    .address {
      font-weight: 600;
      word-break: break-all;
    }

    .copy-btn {
      padding: 0;
      min-width: 36px;
      border-width: 2px;

      &--desktop {
        display: none;
        font-size: 12px;
        padding: 0 20px;
      }
    }

    @media screen and (min-width: 600px) {
      padding: 18px 28px;
      width: calc(100% - 36px);

      .copy-btn {
        display: none;

        &--desktop {
          display: block;
        }
      }
    }
  }

  .thank-you {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #FFFDD4;
    text-align: center;

    &::after, &::before {
      content: '';
      position: absolute;
      display: block;
      width: 9px;
      height: 9px;
      transform: rotate(45deg);
      background-color: $primary;
    }

    &::after {
      right: -24px;
      top: 6px;
    }

    &::before {
      left: -24px;
      top: 6px;
    }
  }

  .ask-questions {
    margin-top: 12px;
  }
}
.back.v-btn {
  margin-bottom: 12px;
  @media screen and (min-width: 768px) {
    padding-left: 0;
  }
}
.mt-10px {
  margin-top: 10px;
}
</style>
