export const currencyItems = [
  { name: 'USD', sign: '$'},
  { name: 'EUR', sign: '€'},
  { name: 'RUB', sign: '₽'},
  { name: 'UAH', sign: '₴'},
  { name: 'GBP', sign: '£'},
  { name: 'KZT', sign: '₸'},
  { name: 'BRL', sign: 'R$'},
  { name: 'TRY', sign: '₺'}
];

export const cryptoItems = [
  {
    id: 1,
    name: 'BNB',
    icon: 'BNB.svg'
  },
  {
    id: 2,
    name: 'BUSD',
    icon: 'BUSD.svg'
  },
  {
    id: 3,
    name: 'STM',
    icon: 'STM.svg'
  },
  {
    id: 4,
    name: 'USDT',
    icon: 'USDT.png'
  },
];

export const swapItems = [
  // {
  //   id: 1,
  //   name: 'ETH',
  //   icon: 'ETH.svg'
  // },
  // {
  //   id: 2,
  //   name: 'BUSD',
  //   icon: 'BUSD.svg'
  // },
  {
    id: 3,
    name: 'STM',
    icon: 'STM.svg',
    address: '0x63d288fd3fc4fbc9cc04cfdb08bb94fdf7112014'
  },
  // {
  //   id: 4,
  //   name: 'USDT',
  //   icon: 'USDT.png'
  // },
  {
    id: 5,
    name: 'BNB',
    icon: 'USDT.png',
    address: '0x0000000000000000000000000000000000000000'
  }
];