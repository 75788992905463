import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/credit-card-validation';
import './plugins/vee-validate';
import './plugins/axios';
import './plugins/vue-tel-input-vuetify';
import router from './router'
import store from './store'
import { api } from '/src/plugins/axios';
Vue.filter('addSpace', function (x) {
  var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
})
Vue.use(require('vue-moment'));
Vue.config.productionTip = false
Vue.prototype.$notifier = ({ message = '', type = '', hash = '' }) => {
  store.commit('showMessage', { message, type, hash })
}
Vue.prototype.$addTokenMetamask = async (token) => {
  await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
          type: 'ERC20',
          options: {
              address: token.address,
              symbol: token.token,
              decimals: token.decimal
          }
      }
  });
}
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
if(params.login) {
  localStorage.setItem("access_token",params.login)
}
api.interceptors.request.use(config => {
  const token = localStorage.getItem("access_token");
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});
if(params.hash) {
  const telegramData = {
    id: params.id,
    auth_date: params.auth_date,
    first_name: params.first_name,
    hash: params.hash,
    last_name: params.last_name,
    photo_url: params.photo_url,
    username: params.username
  }
  let telegramDataFiltered = Object.fromEntries(Object.entries(telegramData).filter(([_, v]) => v != null));// eslint-disable-line no-unused-vars
  api.post('verifyTelegram', telegramDataFiltered).then(async ({data}) => {
    localStorage.setItem('access_token', data.accessToken);
    localStorage.setItem('from_telegram', true);
    authMe();
  })
}
else {
  authMe()
}
function authMe() {
  api.get('auth/me').then(({data}) => {
    store.commit('SET_PROFILE', data)
  }).finally(() => {
    new Vue({
      store,
      vuetify,
      router,
      render: h => h(App)
    }).$mount('#app')
  })
}


api.interceptors.response.use(function(response) {
  if(response?.data?.show_message === true) {
    store.commit('showNotification', { message: response?.data?.message,  type: 'success' })
  }
  return response
}, function(err) {
  try {
    const resData = err.response.data;
    if(resData.statusCode === 500) {
      store.commit('showNotification', { message: 'Something went wrong', type: 'error' })
    }
    else if (Array.isArray(resData.message)) {
      const errorText = resData.message.map(m => m[0].toUpperCase() + m.slice(1)).join('<br>')
      store.commit('showNotification', { message: errorText,  type: 'error' })
    } else {
      store.commit('showNotification', { message: resData.message,  type: 'error' })
    }
    if(resData.statusCode === 401 && err.response?.config?.url != 'auth/me') {
      router.push('/signin')
    }
  } catch(error) {
    store.commit('showNotification', { message: 'Something went wrong',  type: 'error' })
  }

  return Promise.reject(err);
})



