<template>
  <v-app>
    <app-header @disconnect="disconnect" @connect="connectWallet"></app-header>
    <div class="page-wrapper">
      <router-view v-if="loaded" @connect="connectWallet"></router-view>
    </div>     
    <notify-dialog />
    <notify-snackbar />
    <v-dialog v-model="dialog" width="460">
      <v-card class="wallets-dialog">
        <v-card-title class="">
          Connect to wallet
        </v-card-title>

        <div class="wallets-dialog-content">
          <v-btn :class="{'d-none d-md-block': provider.hideMobile}" @click="handleConnect(provider.providerName,provider.mobileLink)" large block color="primary" outlined v-for="(provider,index) in providers" :key="index">
            {{ provider.name }}
            <img :src="provider.logo" :alt="provider.alt" :class="provider.class" />
          </v-btn>
        </div>

      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// import WalletConnectProvider from "@walletconnect/web3-provider";
import AppHeader from './components/AppHeader.vue';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import NotifyDialog from './components/NotifyDialog.vue';
import NotifySnackbar from './components/NotifySnackbar.vue';
import { PROVIDERS } from '@/constants/index';
import { mapActions,mapGetters } from "vuex";
// import { api } from '/src/plugins/axios';
export default {
  name: 'App',
  components: {
    AppHeader,
    NotifyDialog,
    NotifySnackbar
  },
  data() {
    return {
      errorText: null,
      successText: null,
      dialog: false,
      errorSnackbar: false,
      successSnackbar: false,
      loaded: false,
      providers: PROVIDERS
    }
  },
  async created() {
    // this.logOut();
    // const telegram = window.Telegram?.WebApp;
    // if(telegram?.initData) {
    //   const query = this.$route.query
    //   console.log(query);
    //   if(query && query.id && query.hash) {
    //   api.post('verifyTelegram', {
    //     id: query.id,
    //     auth_date: query.auth_date,
    //     first_name: query.first_name,
    //     hash: query.hash,
    //     last_name: query.last_name,
    //     photo_url: query.photo_url,
    //     username: query.username
    //     }).then(async ({data}) => {
    //     localStorage.setItem('access_token', data.accessToken);
    //     await this.getProfile();
    //   })
    // }
    
    const { ethereum } = window;
    if(window.innerWidth <= 768 && ethereum && ethereum.isMetaMask) {
      this.handleConnect('injected')
    }
    await this.getTokens();
    this.loaded = true;
    // if(window.ethereum) {
    //   this.setAccount(window.ethereum)
    // }
    // else if(window.BinanceChain) {
    //   this.setAccount(window.BinanceChain)
    // }
  },
  computed: {
    ...mapGetters({user: 'user'})
  },
  methods: {
    ...mapActions(['setAccount','getTokens','logOut','getProfile']),
    disconnect() {
      this.logOut();
    },
    connectWallet() {
      // const { ethereum } = window;
      // if(window.innerWidth <= 768 && ethereum && ethereum.isMetaMask) {
      //   this.handleConnect('injected')
      // } else {
        this.dialog = true;
      // }
    },
    async setWeb3Provider(p) {
      let providerObject;
      switch (p) {
        case 'injected':
          if(!window.ethereum) {
            this.$notifier({ message: 'Please first install metamask wallet to your browser', type: 'error' });
            return;
          }
          providerObject = window.ethereum;
          break;
        case 'walletconnect':
          this.dialog = false;
          providerObject = await EthereumProvider.init({
            projectId: '476225eea922e35af1e87bf2ff06d7f3',
            chains: [56],
            showQrModal: true
          })
          // providerObject = new WalletConnectProvider({
          //   // chainId: process.env.VUE_APP_CHAIN_ID,
          //   network: 'binance',
          //   rpc: {
          //     97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
          //     56: "https://bsc-dataseed.binance.org/"
          //   },
          // });
          break;
        case 'binance': {
          if(!window.BinanceChain) {
            this.$notifier({ message: 'Please first install Binance Chain Wallet to your browser', type: 'error' });
            return;
          }
          providerObject = window.BinanceChain;
          break;
        }
      }
      await providerObject.enable();
      this.setAccount(providerObject);
      this.dialog = false;
    },
    async handleConnect(provider,link = false) {
      const { ethereum } = window;
      const isMetaMask = ethereum && !ethereum?.isMetaMask
      if(link && window.innerWidth <= 768 && !isMetaMask) {
        var url = new URL(window.location.href)
        url.searchParams.set('login', localStorage.getItem("access_token"));
        const linkToRedirect = url.href.replace('https://','')
        window.location.href = 'https://metamask.app.link/dapp/'+linkToRedirect;
        return
      }
      try {
        // await this.$localForage.setItem('provider', provider);
        await this.setWeb3Provider(provider);
        // await this.login();
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style lang="scss">
@import "./styles/index.scss";

.page-wrapper {
  display: flex;
  padding: 40px 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.wallets-dialog {
  &-content {
    padding: 30px;
    display: grid;
    gap: 20px;
    background-color: #1F2822;
    .v-btn__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: $white;
    }
  }
}
</style>
