<template>
  <v-snackbar :top="true" v-model="show" multi-line outlined :color="colors[type]" rounded :timeout="type == 'error' ? 3000 : -1">
    <span v-html="message"></span>
    <template v-if="type == 'success'" v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="show = false"
        color="#FFFFFF"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'NotifySnackbar',
  data() {
    return {
      show: false,
      message: '',
      type: 'error',
      colors: {
        error: 'red',
        success: 'green'
      }
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showNotification') {
        this.message = state.notification.message
        this.type = state.notification.type
        this.show = true
      }
    })
  }
};
</script>

<style lang="scss" scoped>

</style>
