<template>
  <div class="buy-form">
    <v-alert
        v-if="showErrorAlert"
        outlined
        type="error"
    >
      <template slot="prepend">
        <img class="mr-3" src="@/assets/images/alert-error-icon.svg" alt="">
      </template>
      Payment error!
    </v-alert>
    <v-alert
        v-if="showSuccessAlert"
        outlined
        type="success"
    >
      <template slot="prepend">
        <img class="mr-3" src="@/assets/images/alert-success-icon.svg" alt="">
      </template>
      Payment was successful
    </v-alert>
    <v-form @submit.prevent="getFormAndSubmit" ref="form" class="form-card">
      <h1 class="buy-form-card-title">Buy cryptocurrency</h1>
      <div>
        <p>Payment method</p>
        <v-radio-group class="radio-btn-wrapper" v-model="form.paymentMethod">
          <v-radio value="adv">
            <template v-slot:label>
              <div class="radio-btn-content">
                AdvCash wallet
                <info-tooltip>
                  Pay with your funds from your AdvCash account.
                </info-tooltip>
              </div>
            </template>
          </v-radio>
          <v-radio value="card">
            <template v-slot:label>
              <div class="radio-btn-content">
                Bank card
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div class="select-section">
        <div>
          <p>Buy</p>
          <token-select :items="buyTokens" v-model="form.buyCurrency"/>
        </div>
        <div>
          <p>Payment currency</p>
          <v-text-field :error-messages="amountError"
                        @focus="focusAmount"
                        single-line
                        v-model="form.paymentAmount"
                        type="number"
                        class="field-with-select right-bordered fw-600"
                        solo flat dense>
            <template v-slot:append>
              <v-select
                  :items="buyFiats"
                  :item-value="item => item.fiat"
                  v-model="form.paymentCurrency"
                  return-object
                  dense
                  hide-details
                  flat
                  :menu-props="{contentClass: 'select-menu currency-menu'}"
              >
                <template slot="selection" slot-scope="data">
                  <span class="currency-sign">{{ data.item.sign }}</span>
                  {{ data.item.fiat }}
                </template>
                <template slot="item" slot-scope="data">
                  <span class="currency-sign">{{ data.item.sign }}</span>
                  {{ data.item.fiat }}
                </template>
                <template slot="append">
                  <img src="@/assets/images/select-arrow.svg" alt="">
                </template>
              </v-select>
            </template>
          </v-text-field>
        </div>
      </div>
      <div>
        <p>Enter your wallet number</p>
        <v-text-field placeholder="BEP-20" solo flat dense :error-messages="addressError"
                      v-model="form.wallet">
          <template slot="append">
            <info-tooltip type="info" :width="400">
              Please ensure that the wallet address is correct! Please ensure
              that the wallet address supports the Binance Smart Chain network.
              You will lose your assets if you enter the wrong address or if
              you use a different network.
            </info-tooltip>
          </template>
        </v-text-field>
      </div>
      <div>
        <div class="total-value-row">
          <span class="total-value-row-hint">I will get ≈</span>
          <div class="total-value-row-right">
            <img :src="form.buyCurrency.image_url" alt="">
            <span class="total-value-row-text fw-600 ml-2">{{ crypto }} {{ form.buyCurrency.token }}</span>
            <v-text-field class="hidden-input ma-0" dense hide-details>
              <template slot="append">
                <info-tooltip :width="400">
                  Your bank may charge additional fees. Check the commission before
                  sending money. After a successful payment crypto will be credited
                  to your wallet within a few minutes.
                </info-tooltip>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
      <div>
        <form ref="acForm" method="POST" action="https://wallet.advcash.com/sci/">
          <input type="hidden" name="ac_account_email">
          <input type="hidden" name="ac_sci_name">
          <input type="hidden" name="ac_amount">
          <input type="hidden" name="ac_currency">
          <input type="hidden" name="ac_order_id">
          <input type="hidden" name="ac_sign">
          <input type="hidden" name="ac_ps">
          <input type="hidden" name="ac_comments" value="Payment">
        </form>
        <v-btn :disabled="submitBtnDisabled" type="submit" class="submit-btn" depressed>
          Buy
        </v-btn>
      </div>
    </v-form>
    <v-dialog v-model="showModal" width="350">
      <div class="info-modal">
        <h3 class="info-modal-title">This currency is not supported</h3>
        <div class="info-modal-content">
          <p>Currency {{form.paymentCurrency.fiat}} is not supported for payment, please leave your telegram username, we will contact you within 2 hours to conduct the transaction</p>
          <v-text-field placeholder="Telegram username" solo flat dense v-model="telegramUsername">
          </v-text-field>
          <v-btn :disabled="!telegramUsername" large @click="createDeal" block depressed color="primary">Create deal</v-btn>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="showSuccessModal" width="350">
      <div class="info-modal">
        <img class="mb-3" :src="require(`@/assets/success.svg`)">
        <h3 class="info-modal-title">Thank you for your request, we will respond to you within 2 hours</h3>
      </div>
    </v-dialog>
  </div>

</template>

<script>
import InfoTooltip from '/src/components/InfoTooltip.vue';
import TokenSelect from '/src/components/TokenSelect.vue';
import { api } from '/src/plugins/axios';
import { currencyItems } from '/src/components/forms/shared';
import { mapGetters } from 'vuex';

export default {
  name: 'BuyForm',
  components: {
    InfoTooltip,
    TokenSelect
  },
  data() {
    return {
      form: {
        paymentMethod: 'card',
        paymentAmount: 0,
        paymentCurrency: {},
        buyCurrency: {},
        wallet: ''
      },
      paymentAmountDebounceTimeoutId: null,
      crypto: 0,
      amountError: null,
      addressError: null,
      acForm: {},
      currencyItems: currencyItems,
      telegramUsername: null,
      // items: cryptoItems,
      showSuccessAlert: false,
      showErrorAlert: false,
      isProcessing: false,
      showModal: false,
      showSuccessModal: false
    };
  },
  computed: {
    submitBtnDisabled() {
      return !this.crypto || !this.form.wallet || this.isProcessing || !!this.addressError;
    },
    ...mapGetters({
      tokens: 'tokens',
      buyFiats: 'buyFiats'
    }),
    buyTokens() {
      return this.tokens.filter(item => item.enable_buy) || [];
    }
  },
  methods: {
    focusAmount() {
      if (this.form.paymentAmount === 0) {
        this.form.paymentAmount = null;
      }
    },
    createDeal() {
      this.getFormAndSubmit(null,true);
    },
    getFormAndSubmit(e,send = false) {
      if(!send && !this.form.paymentCurrency.manual_buy) {
          this.showModal = true;
          return
      }
      api.post('change', {
        "token": this.form.buyCurrency.token,
        "fiat": this.form.paymentCurrency.fiat,
        "count": this.form.paymentAmount,
        "wallet": this.form.wallet,
        "type_pay": this.form.paymentMethod,
        "username": this.telegramUsername
      }).then(({data}) => {
        if(data.messageSent) {
          this.showModal = false;
          this.username = null;
          this.showSuccessModal = true;
          this.form.paymentAmount = 0;
          return
        }
        this.$refs.acForm.ac_account_email.value = data.form.ac_account_email;
        this.$refs.acForm.ac_sci_name.value = data.form.ac_sci_name;
        this.$refs.acForm.ac_amount.value = data.form.ac_amount;
        this.$refs.acForm.ac_currency.value = data.form.ac_currency;
        this.$refs.acForm.ac_order_id.value = data.form.ac_order_id;
        this.$refs.acForm.ac_sign.value = data.form.ac_sign;
        this.$refs.acForm.ac_ps.value = data.form.ac_ps;
        this.$refs.acForm.submit();
      });
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$router.replace({ query: { token: val.buyCurrency.token }}).catch(() => {})
        if (!val.paymentAmount) return;
        if (this.paymentAmountDebounceTimeoutId !== null) {
          clearTimeout(this.paymentAmountDebounceTimeoutId);
        }
        this.paymentAmountDebounceTimeoutId = setTimeout(() => {
          this.paymentAmountDebounceTimeoutId = null;
          this.isProcessing = true;
          api.post('info', {
            "token": val.buyCurrency.token,
            "fiat": val.paymentCurrency.fiat,
            "count": val.paymentAmount,
            "wallet": val.wallet,
            "type_pay": val.paymentMethod
          }).then(({data}) => {
            this.isProcessing = false;
            if (data.error_count) {
              this.amountError = data.error_count.currency;
              this.crypto = 0;
              return;
            }
            if (data.error_address) {
              this.addressError = data.error_address.currency;
              return;
            }
            this.amountError = null;
            this.addressError = null;
            this.crypto = data.crypto;
          });
        }, 400);
      },
      deep: true
    }
  },
  mounted() {
    if(this.$route.query.token) {
      const token = this.buyTokens.find(item => item.token == this.$route.query.token);
      if(token) this.form.buyCurrency = token;
      else this.form.buyCurrency = this.buyTokens[0];
    }
    else {
      this.form.buyCurrency = this.buyTokens[0];
    }
    this.form.paymentCurrency = this.buyFiats[0];
  }
};
</script>

<style lang="scss" scoped>
.form-card {
  --from-card-width: 615px;
}

.radio-btn-wrapper {
  margin: 0;
  padding: 0;

  .v-input--selection-controls__input .v-input--selection-controls__ripple {
    display: none;
  }

  .v-icon.mdi-radiobox-marked {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      transition: .1s;
      content: "";
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background-color: $white;
      border: 5px solid $primary;
      box-sizing: content-box;
    }
  }

  .v-icon.mdi-radiobox-blank {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      content: ""
    }
  }

  ::v-deep .v-input--radio-group__input {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .v-radio {
      padding: 14px;
      border: 1px solid $grey-default;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: .3s;

      &:first-child {
        margin-bottom: 0;
      }

      &.v-item--active {
        background-color: $grey-default;

        .v-label {
          color: $primary;
        }
      }

      .radio-btn-content {
        width: 100%;
        font-size: 14px;
        padding-left: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
