<template>
  <header class="header">
    <div class="header-content">
      <img class="header-fixed-img" src="@/assets/images/beta.png" alt="" />
      <a class="logo-link" href="https://cvl.network">
        <img class="header-content-logo" src="@/assets/logo.svg" alt="logo" />
      </a>
      <div class="header-content-links d-none d-sm-flex">
        <router-link class="link-item" to="/buy">
          Buy
          <v-icon class="ml-2"> mdi-credit-card-outline </v-icon>
        </router-link>
        <router-link class="link-item" to="/sell">
          Sell
          <v-icon class="ml-2"> mdi-credit-card-outline </v-icon>
        </router-link>
        <router-link class="link-item" to="/trade">CVLSwap</router-link>
        <router-link class="link-item" to="/profile/offers"
          >Farm</router-link
        >
        <a
          target="_blank"
          class="link-item"
          href="https://t.me/civilization_x_bot"
          >TGWallet</a
        >
      </div>
      <div class="d-flex">
        <div class="d-none d-sm-flex align-center">
          <!-- <template v-if="!user">
            <router-link class="link-item" to="/signin">Log In</router-link>
            <router-link class="link-item" to="/signup">Register</router-link>
          </template>
          <template v-else>
            <router-link class="link-item profile-link" to="/profile">Profile</router-link>
            <span @click="logout" class="link-item logout-link">Log out</span>
          </template> -->
        </div>
        <v-btn
          depressed
          v-if="!account"
          @click="$emit('connect')"
          color="primary"
        >
          Connect wallet
        </v-btn>
        <v-btn
          @click="networkModal = true"
          v-else-if="chainId && !isCorrectChainId"
          color="red"
          >Wrong network</v-btn
        >
        <div class="balance-card" v-else>
          <span class="pl-3 d-none d-sm-block"> {{ balance.BNB }} BNB </span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed color="primary">
                {{ account.slice(0, 5) + "....." + account.slice(-5) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="$emit('connect')"
                link
                class="d-none d-sm-flex"
              >
                <v-list-item-title>Change network</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('disconnect')" link>
                <v-list-item-title>Disconnect</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-btn @click="drawer = true" text class="menu-btn d-sm-none">
        <v-icon> mdi-menu </v-icon>
      </v-btn>
      <v-navigation-drawer
        v-model="drawer"
        fixed
        right
        class="d-sm-none mobile-nav"
      >
        <div>
          <div class="mobile-nav-header">
            <span>Menu</span>
            <v-btn text @click="drawer = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
          <div class="mobile-nav-content">
            <router-link class="link-item" to="/buy">
              Buy
              <v-icon class="ml-2"> mdi-credit-card-outline </v-icon>
            </router-link>
            <router-link class="link-item" to="/sell">
              Sell
              <v-icon class="ml-2"> mdi-credit-card-outline </v-icon>
            </router-link>
            <router-link class="link-item" to="/trade">CVLSwap</router-link>
            <router-link class="link-item" to="/profile/offers"
              >Farm</router-link
            >
            <a
              target="_blank"
              class="link-item"
              href="https://t.me/civilization_x_bot"
              >TGWallet</a
            >
            <!-- <template v-if="!user">
              <router-link class="link-item" to="/signin">Log In</router-link>
              <router-link class="link-item" to="/signup">Register</router-link>
            </template>
            <template v-else>
              <router-link class="link-item profile-link" to="/profile">Profile</router-link>
              <span @click="logout" class="link-item logout-link">Log out</span>
            </template> -->
          </div>
        </div>
      </v-navigation-drawer>
    </div>
    <v-dialog width="350" v-model="networkModal">
      <div class="network-modal">
        <p>Please choose "Binance Smart Chain" network in your wallet or click "Disconnect" and make new connection</p>
        <v-btn depressed color="secondary" @click.stop="networkModal = false" @click="$emit('disconnect')">Disconnect</v-btn>
      </div>
    </v-dialog>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppHeader",
  data() {
    return {
      drawer: false,
      dialog: false,
      group: null,
      networkModal: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      account: "account",
      balance: "balance",
      chainId: "chainId",
      isCorrectChainId: "isCorrectChainId",
    }),
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      this.$store.commit('SET_PROFILE',null)
      this.$router.push('/signin');
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-family: $body-font-family, sans-serif;
  padding: 16px;
  padding-left: 32px;
  .balance-card {
    white-space: nowrap;
    border: 2px solid $primary;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 16px;
  }
  &-content {
    display: flex;
    align-items: center;
    // margin-right: 12px;
    justify-content: space-between;
    width: fit-content;
    width: 100%;
    .menu-btn {
      @media screen and (min-width: 768px) {
        margin-left: auto;
      }
    }
    .logo-link {
      margin-right: 12px;
      display: flex;
      img {
        width: 42px;
        height: 42px;
      }
    }

    &-links {
      box-sizing: border-box;
      margin: 0;
      min-width: 0;
      flex: 1;
      display: flex;
      padding: 0;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      // justify-content: center;
    }

    .link-item {
      display: flex;
      flex-flow: row nowrap;
      border-radius: 3rem;
      outline: none;
      cursor: pointer;
      text-decoration: none;
      color: $white;
      font-size: 1rem;
      width: fit-content;
      margin: 0 12px;
      font-weight: 500;
      transition: .3s;
      &:hover {
        color: $primary;
        i {
          color: $primary;
        }
      }
    }

    .logout-link {
      opacity: 0.8;
    }

    .link-item.router-link-active:not(.profile-link) {
      font-weight: 600;
      color: $primary;
      i {
        color: $primary;
      }
    }
  }
}
.v-navigation-drawer.mobile-nav {
  background-color: $secondary;
}
.mobile-nav {
  &-header {
    padding: 16px 26px 16px 40px;
    background-color: $dark-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $grey-light;
    font-size: 18px;
  }
  .mobile-nav-content {
    padding-top: 16px;
    padding-left: 40px;
    .link-item {
      padding: 10px 0;
    }
  }
}
.header-fixed-img {
  position: absolute;
  left: 0;
  top: 0;
}
.network-modal {
  padding: 40px;
  text-align: center;
  font-size: 16px;
  background-color: $secondary;
}
</style>
