<template>
    <v-select
        v-model="inputVal"
        :items="renderTokens"
        :error-messages="errors"
        :item-value="item => item.id"
        item-text="token"
        return-object
        dense
        solo
        flat
        small-chips
        hide-details
        :menu-props="{offsetY: true,'nudge-top': 0, contentClass: 'token-select'}"
        class="token-select"
    >
        <template slot="selection" slot-scope="data">
            <div class="list-item list-selection">
                <img class="token-img" :src="data.item.image_url" alt="">
                <div class="list-item__title">
                    <span class="list-item__title-token">{{ data.item.token }}</span>
                </div>
                <v-btn v-if="isMetaMask && data.item.token != 'BNB'" class="metamask-btn px-0 ml-2" text @click.stop="$addTokenMetamask(data.item)">
                    <img src="@/assets/images/wallets/metamask.svg" alt="" class="ma-0">
                </v-btn>
            </div>
          </template>
          <template slot="prepend-item">
            <v-text-field class="search-input py-0 ma-0" v-model="search" prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
          </template>
          <template slot="item" slot-scope="data">
            <div class="list-item">
                <img class="token-img" :src="data.item.image_url" alt="">
                <div class="list-item__title">
                    <span class="list-item__title-token">{{ data.item.token }}</span>
                    <span class="list-item__title-desc">{{ data.item.name }}</span>
                </div>
                <span class="list-item__price">{{parseFloat(data.item.price_in_usd)}} USD</span>
            </div>
          </template>
          <template slot="append">
            <img src="@/assets/images/select-arrow.svg" alt="">
        </template>
    </v-select>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'TokenSelect',
    props: ['value','errors','items'],
    data() {
        return {
            token: null,
            search: null
        }
    },
    computed: {
        ...mapGetters({isMetaMask: 'isMetaMask'}),
        renderTokens() {
            return this.search ? [...this.items.filter(item => item.token.toLowerCase().includes(this.search.toLowerCase())), this.inputVal] : this.items;
        },
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.search = null;
                this.$emit('input', val);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.search-input {
    background-color: $secondary;
    ::v-deep .v-input__slot {
        padding-left: 10px;
        &:before, &:after {
            display: none;
        }
    }
}
.list-item {
    grid-template-columns: 24px 1fr 20px;
    display: grid;
    margin-right: 12px;
    gap: 8px;
    align-items: center;
    &:not(.list-selection) {
        width: 100%;
        grid-template-columns: 24px 1fr 140px;
        padding: 6px 0;
    }
    .token-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: contain;
    }
    &__title {
        display: flex;
        flex-direction: column;
        &-token {
            font-size: 18px;
            line-height: 1;
            color: $white;
        }
        &-desc {
            font-size: 14px;
            color: $grey-light;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
    &__price {
        text-align: right;
    }
}
</style>