<template>
  <v-alert outlined type="error">
    <template slot="prepend">
      <img class="mr-3" src="@/assets/images/alert-error-icon.svg" alt="" />
    </template>
    Your session is expired
  </v-alert>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
</style>