<template>
  <div>
    <loading text="Please wait" :show="true"/>
  </div>
</template>
<script>
import { api } from '/src/plugins/axios';
import { mapActions } from 'vuex';
import Loading from '/src/components/Loading';
export default {
  components: {Loading},
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(['getProfile']),
  },
  created() {
    const query = this.$route.query
    if(query && query.id && query.hash) {
      api.post('verifyTelegram', query).then(async ({data}) => {
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('from_telegram', true);
        await this.getProfile();
        this.$router.push('/buy-farm/presale?sell=BNB')
      })
    }
    else {
      this.$router.push('/');
    }
  }
}
</script>