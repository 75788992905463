<template>
  <div class="buy-form">
    <v-form lazy-validation ref="form" v-model="valid" class="form-card no-gap" @submit.prevent="swap">
      <div class="d-flex mb-3">
        <router-link tag="button" class="mr-3" to="/trade">
          <v-img class="mr-2" src="@/assets/images/arrow_yellow.svg" />
        </router-link>
        <h1 class="buy-form-card-title">Swap</h1>
      </div>
      <p class="mb-2">Pay</p>
      <div class="select-section-grid is-swap">
        <div class="text-center">
          <token-select :items="sellTokenItems" v-model="form.sellCurrency" />
          <v-btn @click="swapCoins" text small class="mt-5 mb-2">
            <v-icon>mdi-swap-vertical</v-icon>
          </v-btn>
        </div>
        <div class="text-right">
          <v-text-field 
            @focus="activeInput = 'sell'" 
            :error-messages="sellAmountError"
            single-line
            reverse
            :rules="sellRules"
            :key="maxSell"
            v-model="form.sellAmount"
            ref="sellInput"
            type="number"
            class="field-with-select fw-600 sell-text-field"
            solo flat dense>
          </v-text-field>
          <v-btn @click="sellToMax" v-if="this.userBalance && maxSell" x-small depressed color="primary">
            Max: {{maxSell}}
          </v-btn>
        </div>
      </div>
      <p class="mb-2">To buy</p>
      <div class="select-section-grid">
        <token-select :items="buyTokenItems" v-model="form.buyCurrency" />
        <v-text-field @focus="activeInput = 'buy'" :error-messages="buyAmountError"
          single-line
          reverse
          v-model="form.buyAmount"
          type="number"
          class="field-with-select fw-600"
          solo flat dense>
        </v-text-field>
      </div>
      <div class="commission-section">
        <span>
          Exchange commission
        </span>
        <v-select
            :items="commissionTokens"
            :item-value="item => item.id"
            return-object
            v-model="commissionToken"
            :rules="commissionRules"
            dense
            solo
            flat
            :menu-props="{contentClass: 'select-menu'}"
        >
          <template slot="selection" slot-scope="data">
            <span class="mr-2">{{ commissionValues[data.item.token] }}</span>
            <img class="token-img" :src="data.item.image_url" alt="">
            {{ data.item.token }}
          </template>
          <template slot="item" slot-scope="data">
            <span class="mr-auto">
              {{ commissionValues[data.item.token] }}
              <v-chip v-if="data.item.token === 'CVL'" color="primary" class="px-2" small>-90%</v-chip>
            </span>
            <img class="token-img" :src="data.item.image_url" alt="">
            {{ data.item.token }}
          </template>
          <template slot="append">
            <img src="@/assets/images/select-arrow.svg" alt="">
          </template>
        </v-select>
      </div>
      <v-btn class="submit-btn" depressed v-if="!account" @click="$emit('connect')">
        Connect wallet
      </v-btn>
      <v-btn :loading="loadingButton" v-else :disabled="disabled" type="submit" class="submit-btn" depressed>
        {{isAllowedBuy && isAllowedCommission ? 'Swap' : `Approve ${approveNeedToken}` }}
      </v-btn>
    </v-form>
    <loading :text="loadingText" :show="loadingSwap"/>
    <v-dialog v-model="showModal" width="350">
      <div class="info-modal">
        <v-progress-circular
          indeterminate
          color="primary"
          class="mb-4"
        ></v-progress-circular>
        <div class="info-modal-content">
          <div v-if="info" class="info-modal-content-row">
            <span>You pay:</span>
            <div class="info-modal-item">
              <span class="mr-auto">{{ info.sell.value }}</span>
              <img :src="info.sell.token.image_url" alt="">
              {{ info.sell.token.token }}
            </div>
          </div>
          <div v-if="info" class="info-modal-content-row">
            <span>You get:</span>
            <div class="info-modal-item">
              <span class="mr-auto">{{ info.buy.value }}</span>
              <img :src="info.buy.token.image_url" alt="">
              {{ info.buy.token.token }}
            </div>
          </div>
          <div v-if="info" class="info-modal-content-row">
            <span>Commission:</span>
            <div class="info-modal-item">
              <span class="mr-auto">{{ info.commission.value }}</span>
              <img :src="info.commission.token.image_url" alt="">
              {{ info.commission.token.token }}
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '/src/components/Loading';
import TokenSelect from '/src/components/TokenSelect';
import { api } from '/src/plugins/axios';

export default {
  name: 'BuyForm',
  components: {
    Loading,
    TokenSelect
  },
  data() {
    return {
      form: {
        sellAmount: null,
        buyAmount: 1,
        sellCurrency: {},
        buyCurrency: {},
      },
      sellRules: [
        v => !this.account || Number(v) <= Number(this.maxSell) || 'Max pay: '+ (this.maxSell || 0)
      ],
      commissionRules: [
        v => {
          if(!this.web3) return true
          const token = v.token;
          if(!token) return true
          const commission = this.commissionValues[token];
          if(!commission) return true
          if(token === this.form.sellCurrency.token) {
            return Number(commission) + Number(this.form.sellAmount) + 0.0015 < Number(this.balance[token]) || 'Not enough funds'
          }
          else {
            return  Number(commission) < Number(this.balance[token]) || 'Not enough funds'
          }
        }
      ],
      valid: false,
      info: null,
      commissionToken: {},
      commissionValues: {},
      activeInput: 'buy',
      paymentAmountDebounceTimeoutId: null,
      sellAmountError: [],
      buyAmountError: [],
      showSuccessAlert: false,
      showErrorAlert: false,
      isProcessing: false,
      approveContract: {},
      contract: null,
      loadingSwap: false,
      loadingText: null,
      userBalance: 0,
      commissionBalance: 0,
      allowedValue: {sell: 0, commission: 0},
      disableWatcher: false,
      timerCount: 59,
      showModal: false,
      timerTimeout: null,
      loadingButton: false
    };
  },
  computed: {
    ...mapGetters({
      web3: 'provider',
      account: 'account',
      tokens: 'tokens',
      commissionTokens: 'commissionTokens',
      balance: 'balance',
      isCorrectChainId: 'isCorrectChainId'
    }),
    disabled() {
      return !this.form.sellCurrency.token ||
      !this.form.buyCurrency.token ||
      !this.form.sellAmount ||
      !this.form.buyAmount ||
      this.form.buyAmount == 0 ||
      !!this.buyAmountError.length ||
      !!this.sellAmountError.length ||
      !this.valid ||
      !this.isCorrectChainId
    },
    isSell() {
      return this.activeInput == 'sell';
    },
    sellTokenItems() {
      return this.tokens.filter(item => item.enable && item.id !== this.form.buyCurrency.id);
    },
    buyTokenItems() {
      return this.tokens.filter(item => item.enable && item.id !== this.form.sellCurrency.id);
    },
    isAllowedBuy() {
      return Number(this.allowedValue.sell) > Number(this.form.sellAmount)
    },
    isAllowedCommission() {
      return Number(this.allowedValue.commission) > Number(this.commissionValues[this.commissionToken.token])
    },
    approveNeedToken() {
      if(!this.isAllowedBuy) return this.form.sellCurrency.token
      if(!this.isAllowedCommission) return this.commissionToken.token
      return ''
    },
    maxSell() {
      if(this.form.sellCurrency.token === 'BNB') {
        let max = Number(this.userBalance - 0.0015).toFixed(8)
        return max > 0 ? max : 0;
      }
      else {
        let max = Number(this.userBalance).toFixed(8)
        return max > 0 ? max : 0;
      }
    }
  },
  methods: {
    sellToMax() {
      this.activeInput = 'sell';
      this.form.sellAmount = this.maxSell;
    },
    startTimer() {
      if (this.timerCount > 1) {
          this.timerTimeout = setTimeout(() => {
              this.startTimer();
              this.timerCount--;
          }, 1000);
      }
    },
    stopTimer() {
      this.timerCount = 59;
      clearTimeout(this.timerTimeout);
    },
    swapCoins() {
      const sellCurrency = this.form.sellCurrency;
      this.form.sellCurrency = this.form.buyCurrency;
      this.form.buyCurrency = sellCurrency;
    },
    async getBalance(token,account) {
      if(!account || !this.web3) return 0
      let result;
      this.loadingSwap = true;
      this.loadingText = 'Getting available balance';
      try {
        if(token == '0x0000000000000000000000000000000000000000') {
          result = await this.web3.eth.getBalance(account)
        }
        else {
          const contract = new this.web3.eth.Contract(require('@/contracts/ERC20.json'), token);
          result = await contract.methods.balanceOf(account).call();
        }
      } catch (error) {
        this.loadingSwap = false;
      }
      this.loadingSwap = false;
      if(!result) return 0;
      return Number(this.web3.utils.fromWei(result)).toFixed(4);
    },
    async swap() {
      if(!this.isAllowedBuy || !this.isAllowedCommission) {
        this.loadingButton = true;
        const token = !this.isAllowedBuy ? 'sell' : 'commission';
        this.approveContract[token].methods.approve(
          process.env.VUE_APP_SWAP_ADDRESS,
          this.web3.utils.toWei(Number(180000000).toString(),'ether')
        ).send({
          from: this.account,
          gasPrice: this.web3.utils.toWei('6', 'gwei')
        }).then(() => {
          this.loadingButton = false;
          this.allowedValue[token] = Number(180000000);
        })
        return
      }
      this.loadingSwap = true;
      this.contract = new this.web3.eth.Contract(require('@/contracts/contract.json'),process.env.VUE_APP_SWAP_ADDRESS);
      this.loadingText = "Creating transaction";
      api.post('swap', {
            "sell_token": this.form.sellCurrency.token,
            "buy_token": this.form.buyCurrency.token,
            "sell_amount": this.form.sellAmount,
            "buy_amount": this.form.buyAmount,
            "commission_token": this.commissionToken.token,
            "commission_amount": this.commissionToken.commission,
            "sell_is_active": this.isSell
          }).then(async ({data}) => {
            this.info = data.info;
            this.showModal = true;
            this.startTimer();
            this.loadingText = "Checking allowance";

            this.loadingText = "Send swap transaction";
            this.contract.methods.swap(
              data.tradeID,
              data.coinIn,
              data.coinOut,
              data.coinCommission,
              data.vFrom,
              data.vOut,
              data.vCommission,
              data.time,
              data.sign
            ).send({
              from: this.account,
              value: data.value.hex,
              gasPrice: this.web3.utils.toWei('6', 'gwei')
            }).then((data) => {
              this.$notifier({ message: "The transaction was successful", type: 'success', hash: data.transactionHash });
            }).catch((data) => {
              this.$notifier({ message: "The transaction failed", type: 'error', hash: data.transactionHash });
            }).finally(() => {
              this.showModal = false;
              this.loadingText = null;
              this.loadingSwap = false;
            });
          }).catch((err) => {
            console.log(err);
            this.loadingSwap = false;
          });
      },
      async checkAllowance(tokenAddress,key) {
        if(!this.account) return;
        if(tokenAddress == '0x0000000000000000000000000000000000000000') {
          this.allowedValue[key] = Number(18000000);
          return
        } 
        const approveContract = new this.web3.eth.Contract(require('@/contracts/ERC20.json'),tokenAddress);
        this.allowedValue[key] = await approveContract.methods.allowance(this.account, process.env.VUE_APP_SWAP_ADDRESS).call().then(value => {
          return value
        });
        this.approveContract[key] = approveContract;
      }
  },
  watch: {
    async account(val) {
      this.userBalance = await this.getBalance(this.form.sellCurrency.address,val);
      this.checkAllowance(this.form.sellCurrency.address,'sell');
      this.checkAllowance(this.commissionToken.address,'commission');
    },
    async balance() {
        this.$refs.form.validate();
    },
    commissionToken: {
      async handler(val) {
        if(!val) return
        this.loadingButton = true;
        await this.checkAllowance(val.address,'commission');
        this.loadingButton = false;
      }
    },
    'form.sellCurrency': {
      async handler(val) {
        if(!val) return
        this.sellAmountError = [];
        this.userBalance = await this.getBalance(val.address,this.account).catch(() => {
          this.sellAmountError.push('Cannot get balance for this token')
        });
        this.loadingButton = true;
        await this.checkAllowance(val.address,'sell');
        this.loadingButton = false;
      }
    },
    form: {
      handler(val) {
        if (this.disableWatcher) return;
        if (!val.sellAmount && !val.buyAmount) return;
        if (this.paymentAmountDebounceTimeoutId !== null) {
          clearTimeout(this.paymentAmountDebounceTimeoutId);
        }
        this.$router.replace({ query: { buy: val.buyCurrency.token, sell: val.sellCurrency.token }}).catch(() => {})
        this.paymentAmountDebounceTimeoutId = setTimeout(async () => {
          if (this.isSell) this.getBuy = true;
          this.paymentAmountDebounceTimeoutId = null;
          this.isProcessing = true;
          api.post('info-swap', {
            "sell_token": val.sellCurrency.token,
            "buy_token": val.buyCurrency.token,
            "sell_amount": this.activeInput == 'sell' ? val.sellAmount : 0,
            "buy_amount": this.activeInput == 'buy' ? val.buyAmount: 0,
            "sell_is_active": this.isSell
          }).then(({data}) => {
            this.isProcessing = false;
            this.buyAmountError = [];
            if (data.error) {
              this.buyAmountError.push(data.error.message);
            }
            this.disableWatcher = true;
            setTimeout(() => {
              this.disableWatcher = false; // Disable watch during set of amount
            },200)
            this.form.buyAmount = data.buy;
            this.form.sellAmount = data.sell;
            this.commissionValues = data.commissions;
            if(!this.commissionToken.token) {
              this.commissionToken = this.commissionTokens[0];
            }
            this.$refs.form.validate();
          });
        }, 400);
      },
      deep: true
    },
    showModal(val) {
      if(!val) {
        this.stopTimer();
      }
    }
  },
  async mounted() {
    let buyItem = {};
    let sellItem = {};
    if(this.$route.query.buy) {
      buyItem = this.tokens.find(item => item.token === this.$route.query.buy);
      if(buyItem.token) this.form.buyCurrency = buyItem;
    }
    if(this.$route.query.sell) {
      sellItem = this.tokens.find(item => item.token === this.$route.query.sell);
      if(sellItem.token && sellItem.token != buyItem.token) this.form.sellCurrency = sellItem;
    }
    else {
      if(buyItem.id != this.tokens[1].id) this.form.sellCurrency = this.tokens[1];
      else this.form.sellCurrency = this.tokens[2];
    }
  }
};
</script>

<style lang="scss" scoped>

.form-card {
  --from-card-width: 615px;
}
.commission-section {
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: 1fr 220px;
}
.info-modal {
  background: $grey-darker;
  padding: 24px;
  text-align: center;
  border: 1px solid $primary;
  border-radius: 8px;
  &-title {
    margin-bottom: 24px;
  }
  &-timer {
    color: $primary;
    font-size: 32px;
  }
  &-item {
    display: grid;
    gap: 10px;
    align-items: center;
    grid-template-columns: 1fr 24px 50px;
    font-weight: 600;
    img {
      width: 24px;
    }
  } 
  &-content {
    display: grid;
    font-size: 16px;
    gap: 10px;
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.sell-text-field {
  ::v-deep {
    .v-text-field__details {
      margin-bottom: 0;
    }
  }
}
</style>
