<template>
  <v-form @submit.prevent="getFormAndSubmit" ref="form" class="">
    <div>
      <p>Payment currency</p>
      <v-text-field
        :error-messages="amountError"
        @focus="focusAmount"
        single-line
        v-model="form.paymentAmount"
        type="number"
        class="field-with-select right-bordered fw-600"
        solo
        flat
        dense
      >
        <template v-slot:append>
          <div class="v-select__selections">
            <span class="currency-sign">$</span>
            USD
          </div>
        </template>
      </v-text-field>
    </div>
    <i-will-get v-if="type.value !== 'level'" :is-farming="this.type.value == 'farming'" :rate="Number(this.type.rate)" :value="Number(crypto)" :commission="Number(this.type.commission)"/>
    <v-btn :disabled="submitBtnDisabled" :loading="loading" type="submit" class="submit-btn" depressed>
      Buy
    </v-btn>
  </v-form>
</template>

<script>
// import InfoTooltip from "/src/components/InfoTooltip.vue";
import { api } from "/src/plugins/axios";
import { currencyItems } from "/src/components/forms/shared";
import { mapGetters } from "vuex";
import IWillGet from './IWillGet.vue';

export default {
  name: "BuyForm",
  components: {
    // InfoTooltip,
    IWillGet
  },
  props: {
    type: {},
    method: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        paymentMethod: "card",
        paymentAmount: 0,
        paymentCurrency: {},
        buyCurrency: {},
        wallet: ""
      },
      paymentAmountDebounceTimeoutId: null,
      crypto: 0,
      pairAmount: 0,
      amountError: null,
      addressError: null,
      acForm: {},
      currencyItems: currencyItems,
      telegramUsername: null,
      // items: cryptoItems,
      showSuccessAlert: false,
      showErrorAlert: false,
      isProcessing: false,
      showModal: false,
      showSuccessModal: false,
      loading: false
    };
  },
  computed: {
    submitBtnDisabled() {
      return !this.crypto
    },
    ...mapGetters({
      tokens: "tokens",
      buyFiats: "buyFiats",
      isMetamask: 'isMetaMask',
      cvlToken: 'cvlToken',
    }),
    buyTokens() {
      return this.tokens.filter((item) => item.enable_buy);
    },
  },
  methods: {
    focusAmount() {
      if (this.form.paymentAmount === 0) {
        this.form.paymentAmount = null;
      }
    },
    getFormAndSubmit() {
      this.loading = true;
      api
        .post(process.env.VUE_APP_BOT_URL+'card-payment', {
          method: this.method,
          amount: this.form.paymentAmount,
          type: this.type.value
        })
        .then(({ data }) => {
          if(data) {
            window.location.href = decodeURIComponent(data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    form: {
      handler(val) {
        this.crypto = Number(val.paymentAmount);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.token) {
      const token = this.tokens.find(
        (item) => item.token == this.$route.query.token
      );
      if (token) this.form.buyCurrency = token;
      else this.form.buyCurrency = this.tokens[0];
    } else {
      this.form.buyCurrency = this.tokens[0];
    }
    this.form.paymentCurrency = this.buyFiats[0];
  },
};
</script>

<style lang="scss" scoped>
.form-card {
  --from-card-width: 615px;
}

.radio-btn-wrapper {
  margin: 0;
  padding: 0;

  .v-input--selection-controls__input .v-input--selection-controls__ripple {
    display: none;
  }

  .v-icon.mdi-radiobox-marked {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      transition: 0.1s;
      content: "";
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background-color: $white;
      border: 5px solid $primary;
      box-sizing: content-box;
    }
  }

  .v-icon.mdi-radiobox-blank {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      content: "";
    }
  }

  ::v-deep .v-input--radio-group__input {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .v-radio {
      padding: 14px;
      border: 1px solid $grey-default;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s;

      &:first-child {
        margin-bottom: 0;
      }

      &.v-item--active {
        background-color: $grey-default;

        .v-label {
          color: $primary;
        }
      }

      .radio-btn-content {
        width: 100%;
        font-size: 14px;
        padding-left: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
