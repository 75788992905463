import Vue from 'vue'
import VueRouter from 'vue-router'
import SwapForm from "/src/components/forms/SwapForm";
import BuyForm from "/src/components/forms/BuyForm";
import SellForm from "/src/components/forms/SellForm";
import TradeTable from "/src/components/TradeTable";
// import SignUp from "/src/pages/Auth/SignUp";
// import SignIn from "/src/pages/Auth/SignIn";
// import Forgot from "/src/pages/Auth/Forgot";
// import Reset from "/src/pages/Auth/Reset";
// import ChangePassword from "/src/pages/Auth/ChangePassword";
// import Profile from "/src/pages/Profile/Profile";
// import SellTab from "/src/pages/Profile/SellTab";
// import FarmingTab from "/src/pages/Profile/FarmingTab";
// import ProfileTab from "/src/pages/Profile/ProfileTab";
import LoginTelegram from "/src/pages/LoginTelegram";
// import SignUpReff from "/src/pages/SignUpReff";
import FarmForm from "/src/components/forms/FarmForm";
import Success from "/src/pages/Statuses/Success";
import Fail from "/src/pages/Statuses/Fail";
import Expired from "/src/pages/Statuses/Expired";
import store from "/src/store"

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/buy' },
    // { 
    //   path: '/signup', 
    //   component: SignUp,
    //   async beforeEnter(to,from,next) {
    //     if(store.state.user) {
    //       next('/profile')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    // {
    //   path: '/signin',
    //   component: SignIn,
    //   async beforeEnter(to,from,next) {
    //     if(store.state.user) {
    //       next('/profile')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    // {
    //   path: '/signup-reff',
    //   component: SignUpReff,
    //   async beforeEnter(to,from,next) {
    //     if(store.state.user) {
    //       next('/profile')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    // { path: '/forgot', component: Forgot },
    // { path: '/reset', component: Reset },
    // {
    //   path: '/change-password',
    //   component: ChangePassword,
    //   async beforeEnter(to, from, next) {
    //     if (store.state.user) {
    //       next()
    //     } else {
    //       next('/signin')
    //     }
    //   }
    // },
    // {
    //   path: '/profile',
    //   component: Profile, async beforeEnter(to, from, next) {
    //     if (store.state.user) {
    //       next()
    //     } else {
    //       next('/signin')
    //     }
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: ProfileTab
    //     },
    //     {
    //       path: "staking",
    //       component: FarmingTab
    //     },
    //     {
    //       path: "offers",
    //       component: SellTab
    //     }
    //   ]
    // },
    { path: '/converter', component: SwapForm },
    { path: '/buy', component: BuyForm },
    { path: '/sell', component: SellForm },
    {
      path: '/buy-farm/:type', component: FarmForm, async beforeEnter(to, from, next) {
        if (store.state.user) {
          next()
        } else {
          next('/signin')
        }
      }
    },
    { path: '/trade', component: TradeTable },
    { path: '/login-telegram', component: LoginTelegram },
    { path: '/success', component: Success },
    { path: '/fail', component: Fail },
    { path: '/payment-expired', component: Expired }
  ]
})
