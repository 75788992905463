<template>
  <div>
    <p class="">I will get ≈</p>
    <div class="total-value-row pl-3">
      <span class="d-flex align-center">
        <img width="24px" :src="cvlToken.image_url" alt="">
        <span class="total-value-row-text fw-600 ml-2">{{cvlToken.token}}</span>
        <v-btn v-if="isMetamask" class="metamask-btn px-0 px-md-2 ml-3" text @click.stop="$addTokenMetamask(cvlToken)">
          <img src="@/assets/images/wallets/metamask.svg" alt="" class="ma-0">
        </v-btn>
      </span>
      <div class="total-value-row-right">
        <span class="total-value-row-text">{{ amount | addSpace }}</span>
      </div>
    </div>
    <!-- <div v-else class="total-value-row pl-3 mt-3">
      <span class="d-flex align-center">
        <div>
          <img width="24px" :src="cvlToken.image_url" alt="">
          <img class="usdt-pair-image" width="24px" src="@/assets/images/USDT.png" alt="">
        </div>
        <span class="total-value-row-text fw-600 ml-2">{{cvlToken.token}}/USDT</span>
      </span>
      <div class="total-value-row-right">
        <span class="total-value-row-text">{{ pairAmount | addSpace }}$</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    isFarming: {
      type: Boolean,
      required: true
    },
    rate: {
      type: Number,
      required: true
    },
    commission: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      amount: 0,
      pairAmount: 0,
    }
  },
  computed: {
    ...mapGetters({
      isMetamask: 'isMetaMask',
      cvlToken: 'cvlToken',
    })
  },
  created() {
    this.calculateAmount(this.value);
  },
  methods: {
    calculateAmount(val) {
      const amount = Number(val/this.rate);
      const amountWithCommission = (amount) - ((amount / 100) * this.commission);
      this.amount = Number(amountWithCommission).toFixed(2);
    }
  },
  watch: {
    value(val) {
      this.calculateAmount(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.usdt-pair-image {
  margin-left: -6px;
}
</style>