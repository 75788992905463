<template>
  <v-alert outlined type="success">
    <template slot="prepend">
      <img class="mr-3" src="@/assets/images/alert-success-icon.svg" alt="" />
    </template>
    Thank you, your payment is being processed by AdvCash
  </v-alert>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>