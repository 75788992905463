<template>
  <v-dialog width="350" v-model="show" :color="colors[type]">
    <div class="notification-dialog">
      <img class="mb-3" :src="require(`@/assets/${type}.svg`)">
      <h3 class="mb-5" v-html="message"></h3>
      <v-btn v-if="hash" :color="type == 'error' ? 'secondary' : 'primary'" depressed target="_blank" :href="link+hash">View on bscscan</v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'NotifySnackbar',
  data() {
    return {
      show: false,
      message: '',
      type: 'error',
      hash: '',
      link: process.env.VUE_APP_BSCAN_LINK+'/tx/',
      colors: {
        error: 'red',
        success: 'green',
        warning: 'yellow',
        info: 'blue'
      }
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage') {
        this.message = state.snackbar.message
        this.type = state.snackbar.type
        this.hash = state.snackbar.hash
        this.show = true
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.notification-dialog {
  background-color: $grey-darker;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  img {
    width: 50px;
  }
}

</style>
