import Vue from 'vue';
import Vuex from 'vuex';
import { api } from '/src/plugins/axios';
import Web3 from 'web3';
import router from '../router'
import ApiModule from './api'

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {ApiModule},
    state: {
        provider: null,
        account: null,
        tokens: [],
        commissionTokens: [],
        fiats: [],
        types: [],
        balance: {BNB:0,CVL:0},
        chainId: null,
        snackbar: {
            message: '',
            type: ''
        },
        notification: {
            message: '',
            type: ''
        },
        user: null
    },
    getters: {
        provider: (state) => state.provider,
        account: (state) => state.account,
        tokens: (state) => state.tokens,
        fiats: (state) => state.fiats,
        commissionTokens: (state) => state.commissionTokens,
        types: (state) => state.types,
        balance: (state) => state.balance,
        chainId: (state) => state.chainId,
        isCorrectChainId: (state) => {
            return !state.chainId ? true : state.chainId == process.env.VUE_APP_CHAIN_ID
        },
        buyFiats: (state) => state.fiats.filter(item => item.enable),
        sellFiats: (state) => state.fiats.filter(item => item.enable_sell),
        user: (state) => state.user,
        isMetaMask: (state) => {return state.account && window.ethereum && window.ethereum.isMetaMask},
        cvlToken: (state) => state.tokens.find(item => item.token == 'USDT') || {}
    },
    mutations: {
        SET_PROFILE(state,user) {
            state.user = user;
        },
        SET_PROVIDER(state,provider) {
            state.provider = provider;
        },
        SET_ACCOUNT(state,account) {
            state.account = account;
        },
        SET_TOKENS(state,items) {
            state.tokens = items;
        },
        SET_COMMISSION_TOKENS(state,items) {
            state.commissionTokens = items;
        },
        SET_FIATS(state,items) {
            state.fiats = items;
        },
        SET_TYPES(state,items) {
            state.types = items;
        },
        SET_BALANCE(state,balance) {
            state.balance = balance;
        },
        SET_CHAIN_ID(state,id) {
            state.chainId = id;
        },
        showMessage(state, payload) {
            state.snackbar.message = payload.message
            state.snackbar.type = payload.type
            state.snackbar.hash = payload.hash
        },
        showNotification(state, payload) {
            state.notification.message = payload.message
            state.notification.type = payload.type
        },
        LOGOUT(state) {
            localStorage.removeItem('walletconnect');
            state.provider = null,
            state.account = null
        }
    },
    actions: {
        setProvider({ commit },provider) {
            commit('SET_PROVIDER',provider);
        },
        async setAccount({ commit,dispatch },providerObject) {
            const web3 = new Web3(providerObject);
            commit('SET_PROVIDER',web3);
            const chainId = await web3.eth.getChainId();
            commit('SET_CHAIN_ID',chainId);
            dispatch('getAccountAndBalance');
            providerObject.on('networkChanged', function(networkId){
                commit('SET_CHAIN_ID',networkId);
                dispatch('getAccountAndBalance');
            });
            providerObject.on('accountsChanged', function(){
                dispatch('getAccountAndBalance');
            });
        },
        async logOut({ commit }) {
            commit('LOGOUT');
        },
        async getAccountAndBalance({ commit,state }) {
            const accounts = await state.provider.eth.getAccounts();
            if(!accounts[0]) return
            commit('SET_ACCOUNT',accounts[0]);
            
            const balance = await state.provider.eth.getBalance(accounts[0])
            let balanceCVL = 0;
            try {
                const contract = new state.provider.eth.Contract(require('@/contracts/ERC20.json'), process.env.VUE_APP_CVL_ADDRESS);
                balanceCVL = await contract.methods.balanceOf(accounts[0]).call();
                balanceCVL = state.provider.utils.fromWei(balanceCVL);
            }
            catch(error) {
                console.log(error);
            }
            commit('SET_BALANCE',{
                BNB: Number(state.provider.utils.fromWei(balance)).toFixed(4), 
                CVL: Number(balanceCVL).toFixed(4)
            });
        },
        async getTokens({commit}) {
            const data = await api.get('tokens').then(({data}) => {
                return data;
            }) 
            commit('SET_TOKENS',data.items)
            commit('SET_COMMISSION_TOKENS', data.commissionTokens);
            commit('SET_TYPES',data.types)
            commit('SET_FIATS',data.fiats)
        },
        async getProfile({ commit,state }) {
            const token = localStorage.getItem("access_token");
            if(token) {
                if(!state.user) {
                    await api.get('auth/me').then(({data}) => {
                        commit('SET_PROFILE', data)
                    })
                }
            } else {
                router.push('/signin')
            }
        }
    }
});
