<template>
    <div v-if="show" class="loading-backdrop">
      <div class="d-flex flex-column align-center">
        <p v-if="text">{{text}}...</p>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>