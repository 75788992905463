<template>
  <div class="countdown">
    <div class="countdown__element">
      <div class="countdown__element-card" title="Minutes">
        {{ mins }}
      </div>
    </div>
    <div class="mx-1">:</div>
    <div class="countdown__element">
      <div class="countdown__element-card" title="Seconds">
        {{ secs }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    diff: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      days: '--',
      hrs: '--',
      mins: '--',
      secs: '--',
      sec: this.diff,
      counted: this.diff,
      countDownIntVal: null,
    }
  },
  mounted() {
    this.countDown()
    this.countDownIntVal = setInterval(this.countDown, 1 * 1000)
  },
  methods: {
    countDown() {
      const days = Math.floor(this.sec / (3600 * 24))
      this.sec -= days * 3600 * 24
      const hrs = Math.floor(this.sec / 3600)
      this.sec -= hrs * 3600
      const mins = Math.floor(this.sec / 60)
      this.sec -= mins * 60
      this.days = this.pad(days)
      this.hrs = this.pad(hrs)
      this.mins = this.pad(mins)
      this.secs = this.pad(this.sec)
      this.sec = this.counted--
      if (this.counted < 0) {
        clearInterval(this.countDownIntVal)
        this.$emit('timer-end');
      }
    },
    pad(t) {
      return (t + '').length < 2 ? this.pad('0' + t + '') : t
    },
  },
}
</script>
<style lang="scss" scoped>
.countdown {
  display: flex;
  color: $primary;
  font-size: 28px;
}
</style>