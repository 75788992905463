<template>
  <div v-if="type" class="form-card">
    <div class="d-flex mb-3 justify-space-between">
      <div class="d-flex">
        <router-link tag="button" class="mr-3" to="/profile/offers">
          <v-img class="mr-2" src="@/assets/images/arrow_yellow.svg" />
        </router-link>
        <h2 class="buy-form-card-title">{{ title }}</h2>
            <countdown
              v-if="typeIsLevel"
              class="countdown"
              :diff="countdownDiff"
              @timer-end="timerEnd"
              >
            </countdown>
      </div>
      <!-- <div v-if="this.$route.params.type !== 'level'" class="form-card-top-right">
        <img class="offer-card-top-left-img" :src="require('@/assets/images/'+type.icon)" alt="">
        <span>{{type.title}}</span>
      </div> -->
    </div>
    <v-radio-group v-if="!typeIsLevel && showPaymentMethods" class="radio-btn-wrapper" v-model="paymentMethod">
      <v-radio value="crypto">
        <template v-slot:label>
          <div class="radio-btn-content">
            Crypto (BEP-20)
          </div>
        </template>
      </v-radio>
       <v-radio value="coinbase">
        <template v-slot:label>
          <div class="radio-btn-content">
            Crypto (Other)
          </div>
        </template>
      </v-radio>
      <v-radio value="world">
        <template v-slot:label>
          <div class="radio-btn-content">
            Bank card
          </div>
        </template>
      </v-radio>
      <!-- <v-radio v-if="userIsRussian" value="rf">
        <template v-slot:label>
          <div class="radio-btn-content">
            Bank card (RU)
          </div>
        </template>
      </v-radio> -->
    </v-radio-group>
    <PayCryptoForm :type="type" @connect="$emit('connect')" v-if="paymentMethod == 'crypto'"/>
    <PayCardForm :type="type" :method="paymentMethod" v-else/>
  </div>
</template>
<script>
import PayCryptoForm from './PayCryptoForm'
import PayCardForm from './PayCardForm'
import {farmingTypes} from '/src/constants'
import Countdown from '/src/components/CountDown';

import { mapGetters,mapActions } from 'vuex'
export default {
  components: { PayCryptoForm, PayCardForm, Countdown },
  data() {
    return {
      paymentMethod: 'crypto',
      types: farmingTypes,
      type: null,
      countdownDiff: null,
      showPaymentMethods: true
    }
  },
  async mounted() {
    await this.getUserAndTokens();
    if(this.$route.query.deposit) {
      this.showPaymentMethods = false;
    }
    if(this.$route.query.created_at) {
      this.countdownDiff = Number(this.$route.query.created_at) + (10 * 60) - Math.floor(Date.now() / 1000);
      console.log(this.countdownDiff);
      if(this.countdownDiff < 0) {
        this.$router.push('/payment-expired')
        return;
      }
    }
    if(this.$route.params.type == 'level') {
      this.type = {value: 'level'}
      return;
    }
    if(!this.types.filter(item => !item.disabled).map(item => item.slug).includes(this.$route.params.type)) {
      this.$router.push('/profile/offers')
    }
    this.type = this.types.find(item => item.slug == this.$route.params.type);
    this.type.rate = this.tokens.find(item => item.type = this.type).price;
  },
  computed: {
    ...mapGetters({ 
      user: "ApiModule/getUser",
      tokens: "ApiModule/getTokens"
    }),
    userIsRussian() {
      return this.user?.language === 'ru';
    },
    typeIsLevel() {
      return this.type.value == 'level';
    },
    title() {
      if(this.typeIsLevel) return 'PAY WITH CRYPTO';
      if(this.$route.query.deposit) return 'DEPOSIT USDT';
      return 'BUY USDT';
    }
  },
  methods: {
    ...mapActions({
      getUserAndTokens: "ApiModule/getUserAndTokens"
    }),
    timerEnd() {
      this.$router.push('/payment-expired');
    }
  }
}
</script>
<style lang="scss" scoped>
.form-card {
  position: relative;
}
.countdown {
  position: absolute;
  right: 8px;
  top: 2px;
}
.buy-form-card-title {
  text-transform: capitalize;
}
.form-card {
  max-width: 612px;
}
.form-card-top-right {
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 30px;
  }
}
.radio-btn-wrapper {
  margin: 0;
  padding: 0;

  .v-input--selection-controls__input .v-input--selection-controls__ripple {
    display: none;
  }

  .v-icon.mdi-radiobox-marked {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      transition: .1s;
      content: "";
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background-color: $white;
      border: 5px solid $primary;
      box-sizing: content-box;
    }
  }

  .v-icon.mdi-radiobox-blank {
    border: 1px solid $grey-default;
    border-radius: 50%;

    &:before {
      content: ""
    }
  }

  ::v-deep .v-input--radio-group__input {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .v-radio {
      padding: 14px;
      border: 1px solid $grey-default;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: .3s;

      &:first-child {
        margin-bottom: 0;
      }

      &.v-item--active {
        background-color: $grey-default;

        .v-label {
          color: $primary;
        }
      }

      .radio-btn-content {
        width: 100%;
        font-size: 14px;
        padding-left: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>