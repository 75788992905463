import metamask from "@/assets/images/wallets/metamask.svg";
import binance from "@/assets/images/wallets/binance-chain.svg";
import wallet from "@/assets/images/wallets/wallet-connect.svg";

export const PROVIDERS = [
  {
    logo: metamask,
    name: "Metamask",
    class:"meta",
    providerName: "injected",
    supportedChains: true,
    indetifier: "isMetaMask",
    web3ObjectName: () => !!window.ethereum,
    mobileLink: true,
    hideMobile: false
  },
  {
    logo: wallet,
    name: "Wallet Connect",
    class:"wallet",
    providerName: "walletconnect",
    supportedChains: true,
    isInstalled: () => true,
    hideMobile: false
  },
  {
    logo: binance,
    name: "Binance Chain Wallet",
    class:"binance",
    providerName: "binance",
    supportedChains: [56, 97],
    web3ObjectName: () => !!window.BinanceChain,
    hideMobile: true
  }
];

export const farmingTypes = [
  {
    value: 'presale',
    slug: 'presale',
    title: 'PRESALE',
    icon: 'offer_1.png',
    rate: 0.005,
    tokens_left: 100,
    disabled: true,
    show: false,
    disabledText: 'SALES ARE CLOSED',
    botton: 'Buy CVL',
    route: '/buy-farm/presale?sell=BNB',
    total: 0,
    slider: 100,
    info: '<p>Tokens during the closed round are sold at a rate of $0.005 and are subject to be unlocked according to the following rules:</p><p>- 100% unlock on January 22, 2023</p><p>This is an exclusive round with the best conditions. All unsold tokens will be burned.</p>'
  },
  {
    value: 'farming',
    slug: 'staking',
    title: 'Staking',
    icon: 'offer_2.png',
    rate: 0.01,
    tokens_left: 100,
    disabled: false,
    show: true,
    disabledText: 'SALES WILL START SOON',
    botton: 'Pay for staking',
    route: '/buy-farm/staking?sell=BNB',
    total: "200000000",
    slider: 15,
    profit: 24,
    commission: 0,
    info: '<p>You are not buying a token, but a stack. At the moment of exiting '+
    'the stacking, you receive a token for the purchased amount and interest from the stacking.</p>'+
    '<p>The big advantage of stacking is that it brings a 78% annual return every second, and all rewards'+
    'are available for withdrawal instantly! At the same time, your liquidity is blocked for 2 months.</p>'
  },
  {
    value: 'whitelist',
    slug: 'whitelist',
    title: 'CVL tokens',
    icon: 'offer_3.png',
    rate: 0.01,
    tokens_left: 100,
    disabled: false,
    show: true,
    disabledText: 'SALES WILL START SOON',
    botton: 'Buy CVL',
    route: '/buy-farm/whitelist?sell=BNB',
    total: "200000000",
    slider: 0,
    commission: 0
  }
]
