<template>
    <div class="trade-container">
        <h1 class="trade-container-title">Trade</h1>
        <v-tabs
        v-model="tab"
        background-color="#314036"
        color="primary"
        >
            <v-tab
                v-for="item in types"
                :key="item"
            >
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-text-field
          single-line
          v-model="search"
          type="text"
          placeholder="Search"
          class="field-with-select fw-600 search-field right-bordered"
          append-icon="mdi-magnify"
          solo flat dense>
        </v-text-field>
        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="item in types"
                :key="item.id"
            >   
                <table class="trade-table">
                    <thead>
                        <tr>
                            <td>Ticker</td>
                            <td></td>
                            <td>Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row,index) in rows[item]" :key="index" @click="goToConverter(row.token)">
                            <td>
                                <div class="first-column">
                                    <img class="first-column-img" :src="row.image_url" alt="token image">
                                    <div class="first-column-content">
                                        <p class="first-column-title">{{row.token}}</p>
                                        <p v-if="row.name" class="first-column-subtitle">{{row.name}}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <v-tooltip v-if="isMetaMask && row.token != 'BNB'" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="metamask-btn px-1" text @click.stop="$addTokenMetamask(row)">
                                            <img src="@/assets/images/wallets/metamask.svg" alt="">
                                        </v-btn>
                                    </template>
                                    <span>Add to metamask</span>
                                </v-tooltip>
                            </td>
                            <td>
                                {{parseFloat(row.price_in_usd)}} USD
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { api } from '/src/plugins/axios';
export default {
    data () {
      return {
        tab: null,
        search: '',
        items: []
      }
    },
    computed: {
        rows() {
            let obj = {};
            this.types.forEach(item => {
                obj[item] = this.items.filter(token => token.type == item)
                if(this.search) {
                    obj[item] = obj[item].filter(item => item.token.toLowerCase().includes(this.search.toLowerCase()))
                }
            });
            return obj
        },
        ...mapGetters({
            types: 'types',
            tokens: 'tokens',
            isMetaMask: 'isMetaMask'
        })
    },
    methods: {
        goToConverter(name) {
            this.$router.push(`/converter?buy=${name}`)
        },
        getActiveTab() {
            const hash = this.$route.hash.substring(1)
            if(hash) {
                this.tab = this.types.findIndex(item => item.toLowerCase().replace(/\s/g, '') == hash.toLowerCase());
            }
            else {
                this.tab = 0;
            }
        }
    },
    created() {
        api.get('trade').then(({data}) => {
            this.items = data;
        })
    },
    mounted() {
        this.getActiveTab();
    },
    watch: {
        $route() {
            this.getActiveTab();
        }
    }
}
</script>
<style lang="scss" scoped>
.trade-container {
    background: #1F2822;
    padding: 30px;
    width: 100%;
    max-width: 1000px;
    border: 1px solid $primary;
    border-radius: 8px;
    min-height: 800px;
    &-title {
        margin-bottom: 36px;
        font-size: 18px;
    }
    @media screen and (max-width: 767px) {
        border: 0;
        border-radius: 0;
    }
    .search-field {
        margin-top: 30px;
    }
    .trade-table {
        border-collapse:separate; 
        border-spacing: 0 6px;
        width: 100%;
        color: $grey-light;
        tbody {
            tr {
                background: #314036;
                margin: 4px 0;
                cursor: pointer;
                transition: .3s;
                &:hover {
                    background-color: rgba(red($primary), green($primary), blue($primary), 0.5);
                }
            }
        }
        td  {
            padding: 16px;
            font-size: 17px;
            @media screen and (max-width: 767px) {
                font-size: 13px;
                padding: 12px 10px;
            }
            &:first-child {
                @media screen and (min-width: 767px) {
                    width: 280px;
                }
            }
            &:nth-child(2) {
              padding-left: 0;  
              padding-right: 0;
            //   @media screen and (max-width: 767px) {
            //     display: none;
            //   }  
            }
        }
        td:last-child {
            text-align: right;
        }
        .first-column {
            display: flex;
            align-items: center;
            &-img {
                width: 42px;
                height: 42px;
                object-fit: contain;
                margin-right: 20px;
                border-radius: 50%;
                 @media screen and (max-width: 767px) {
                    width: 32px;
                    height: 32px;
                    margin-right: 12px;
                }
            }
            &-title {
                font-size: 18px;
                line-height: 1;
                font-weight: 600;
                color: $white;
                margin: 0;
                max-width: 170px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 4px;
            }
            &-subtitle {
                margin: 0;
                font-size: 14px;
                line-height: 1;
                @media screen and (max-width: 767px) {
                    font-size: 11px;
                }
            }
        }
        // @media screen and (max-width: 767px) {
        //     td:nth-last-child(-n+2) {
        //         display: none;
        //     }
        // }
    }
}
</style>