<template>
  <div class="buy-form">
    <v-form lazy-validation ref="form" v-model="valid" class="no-gap" @submit.prevent="swap">
      <p class="mb-2">Pay</p>
      <div class="select-section-grid is-swap mb-6">
        <div class="text-center">
          <token-select :items="sellTokenItems" v-model="form.sellCurrency" />
          <!-- <v-btn @click="swapCoins" text small class="mt-5 mb-2">
            <v-icon>mdi-swap-vertical</v-icon>
          </v-btn> -->
        </div>
        <div class="text-right">
          <v-text-field 
            @focus="activeInput = 'sell'" 
            :error-messages="sellAmountError"
            single-line
            reverse
            :rules="sellRules"
            :key="maxSell"
            v-model="form.sellAmount"
            :value="form.sellAmount"
            :readonly="type.value == 'level'"
            ref="sellInput"
            type="number"
            class="field-with-select fw-600 sell-text-field"
            solo flat dense>
          </v-text-field>
          <v-btn @click="sellToMax" v-if="this.userBalance && maxSell" x-small depressed color="primary">
            Max: {{maxSell}}
          </v-btn>
        </div>
      </div>
      <i-will-get v-if="type.value !== 'level'" :is-farming="this.type.value == 'farming'" :rate="Number(this.type.rate)" :commission="Number(this.type.commission)" :value="Number(form.buyAmount)"/>
      <v-btn class="submit-btn" depressed v-if="!account" @click="$emit('connect')">
        Connect wallet
      </v-btn>
      
      <v-btn :loading="loadingButton" v-else :disabled="disabled" type="submit" class="submit-btn" depressed>
        {{isAllowedBuy && isAllowedCommission ? 'Buy' : `Approve ${approveNeedToken}` }}
      </v-btn>
    </v-form>
    <loading :text="loadingText" :show="loadingSwap"/>
    <v-dialog v-model="showModal" width="350">
      <div class="info-modal">
        <v-progress-circular
          indeterminate
          color="primary"
          class="mb-4"
        ></v-progress-circular>
        <p>Sending transaction</p>
      </div>
    </v-dialog>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '/src/components/Loading';
import TokenSelect from '/src/components/TokenSelect';
import { api } from '/src/plugins/axios';
import IWillGet from './IWillGet.vue';
export default {
  name: 'BuyForm',
  components: {
    Loading,
    TokenSelect,
    IWillGet
  },
  props: {
    type: {}
  },
  data() {
    return {
      crypto: 0,
      form: {
        sellAmount: null,
        buyAmount: 1,
        sellCurrency: {},
        buyCurrency: {},
      },
      pairAmount: 1,
      sellRules: [
        v => !this.account || Number(v) <= Number(this.maxSell) || 'Max pay: '+ (this.maxSell || 0)
      ],
      valid: false,
      info: null,
      commissionToken: {},
      commissionValues: {},
      activeInput: 'buy',
      paymentAmountDebounceTimeoutId: null,
      sellAmountError: [],
      buyAmountError: [],
      showSuccessAlert: false,
      showErrorAlert: false,
      isProcessing: false,
      approveContract: {},
      contract: null,
      loadingSwap: false,
      loadingText: null,
      userBalance: 0,
      commissionBalance: 0,
      allowedValue: {sell: 0, commission: 0},
      disableWatcher: false,
      timerCount: 59,
      showModal: false,
      timerTimeout: null,
      loadingButton: false,
      uuid: null
    };
  },
  computed: {
    ...mapGetters({
      web3: 'provider',
      account: 'account',
      tokens: 'tokens',
      commissionTokens: 'commissionTokens',
      balance: 'balance',
      isCorrectChainId: 'isCorrectChainId',
      isMetamask: 'isMetaMask',
      cvlToken: 'cvlToken',
      tpyes: 'ApiModule/tokens'
    }),
    disabled() {
      return !this.form.sellCurrency.token ||
      !this.form.sellAmount ||
      !this.form.buyAmount ||
      this.form.buyAmount == 0 ||
      !!this.sellAmountError.length ||
      !this.valid ||
      !this.isCorrectChainId
    },
    isSell() {
      return this.activeInput == 'sell';
    },
    sellTokenItems() {
      if(this.$route.query.deposit) return this.tokens.filter(item => item.token === 'USDT');
      return this.tokens.filter(item => item.enable && ['BUSD','USDT','BNB','DOGE','SHIBA','CVL'].includes(item.token));
    },
    isAllowedBuy() {
      return Number(this.allowedValue.sell) > Number(this.form.sellAmount)
    },
    isAllowedCommission() {
      return Number(this.allowedValue.commission) > Number(this.commissionValues[this.commissionToken.token])
    },
    approveNeedToken() {
      if(!this.isAllowedBuy) return this.form.sellCurrency.token
      if(!this.isAllowedCommission) return this.commissionToken.token
      return ''
    },
    maxSell() {
      if(this.form.sellCurrency.token === 'BNB') {
        let max = Number(this.userBalance - 0.0015).toFixed(8)
        return max > 0 ? max : 0;
      }
      else {
        let max = Number(this.userBalance).toFixed(8)
        return max > 0 ? max : 0;
      }
    }
  },
  methods: {
    ...mapActions({ 
      getUserAndTokens: "ApiModule/getUserAndTokens"
    }),
    sellToMax() {
      this.activeInput = 'sell';
      this.form.sellAmount = this.maxSell;
    },
    startTimer() {
      if (this.timerCount > 1) {
          this.timerTimeout = setTimeout(() => {
              this.startTimer();
              this.timerCount--;
          }, 1000);
      }
    },
    stopTimer() {
      this.timerCount = 59;
      clearTimeout(this.timerTimeout);
    },
    async getBalance(token,account) {
      if(!account || !this.web3) return 0
      let result;
      this.loadingSwap = true;
      this.loadingText = 'Getting available balance';
      try {
        if(token == '0x0000000000000000000000000000000000000000') {
          result = await this.web3.eth.getBalance(account)
        }
        else {
          const contract = new this.web3.eth.Contract(require('@/contracts/ERC20.json'), token);
          result = await contract.methods.balanceOf(account).call();
        }
      } catch (error) {
        this.loadingSwap = false;
      }
      this.loadingSwap = false;
      if(!result) return 0;
      return Number(this.web3.utils.fromWei(result)).toFixed(4);
    },
    async swap() {
      if(!this.isAllowedBuy || !this.isAllowedCommission) {
        this.loadingButton = true;
        const token = !this.isAllowedBuy ? 'sell' : 'commission';
        this.approveContract[token].methods.approve(
          process.env.VUE_APP_SWAP_ADDRESS,
          this.web3.utils.toWei(Number(180000000).toString(),'ether')
        ).send({
          from: this.account,
          gasPrice: this.web3.utils.toWei('6', 'gwei')
        }).then(() => {
          this.loadingButton = false;
          this.allowedValue[token] = Number(180000000);
        })
        return
      }
      this.loadingSwap = true;
      this.contract = new this.web3.eth.Contract(require('@/contracts/contract.json'),process.env.VUE_APP_SWAP_ADDRESS);
      this.loadingText = "Creating transaction";
      api.post('swap-cvl', {
            "type": this.type.value,
            "sell_token": this.form.sellCurrency.token,
            "buy_token": 'CVL_PRESALE',
            "sell_amount": this.form.sellAmount,
            "buy_amount": this.form.buyAmount,
            "commission_token": this.commissionToken.token,
            "commission_amount": this.commissionToken.commission,
            "sell_is_active": this.isSell,
            "uuid": this.uuid,
            "sign": this.sign
          }).then(async ({data}) => {
            this.info = data.info;
            this.showModal = true;
            this.startTimer();
            this.loadingText = "Checking allowance";

            this.loadingText = "Send swap transaction";
            this.contract.methods.swap(
              data.tradeID,
              data.coinIn,
              data.coinOut,
              data.coinCommission,
              data.vFrom,
              data.vOut,
              data.vCommission,
              data.time,
              data.sign
            ).send({
              from: this.account,
              gasPrice: this.web3.utils.toWei('6', 'gwei'),
              value: data.value.hex
            }).then(() => {
              api.post('check-offers').then(async () => {
                await this.getUserAndTokens(true);
                this.showModal = false;
                this.loadingText = null;
                this.loadingSwap = false;
                const isFromTelegram = localStorage.getItem('from_telegram');
                if(isFromTelegram) {
                  window.location.href = 'https://t.me/civilization_x_bot';
                }
                else if(window.Telegram?.webApp) {
                 window.Telegram?.webApp.close();
                } else {
                  this.$router.push('/profile/staking')
                }
              })
            }).catch((data) => {
              this.$notifier({ message: "The transaction failed", type: 'error', hash: data.transactionHash });
              this.showModal = false;
              this.loadingText = null;
              this.loadingSwap = false;
            })
          }).catch((err) => {
            console.log(err);
            this.loadingSwap = false;
          });
      },
      async checkAllowance(tokenAddress,key) {
        if(!this.account) return;
        if(tokenAddress == '0x0000000000000000000000000000000000000000') {
          this.allowedValue[key] = Number(18000000);
          return
        } 
        const approveContract = new this.web3.eth.Contract(require('@/contracts/ERC20.json'),tokenAddress);
        this.allowedValue[key] = await approveContract.methods.allowance(this.account, process.env.VUE_APP_SWAP_ADDRESS).call().then(value => {
          return this.web3.utils.fromWei(value)
        });
        this.approveContract[key] = approveContract;
      }
  },
  watch: {
    async account(val) {
      this.userBalance = await this.getBalance(this.form.sellCurrency.address,val);
      this.checkAllowance(this.form.sellCurrency.address,'sell');
      this.checkAllowance(this.commissionToken.address,'commission');
    },
    async balance() {
        this.$refs.form.validate();
    },
    commissionToken: {
      async handler(val) {
        if(!val) return
        this.loadingButton = true;
        await this.checkAllowance(val.address,'commission');
        this.loadingButton = false;
      }
    },
    'form.sellCurrency': {
      async handler(val) {
        if(!val) return
        this.sellAmountError = [];
        this.userBalance = await this.getBalance(val.address,this.account).catch(() => {
          this.sellAmountError.push('Cannot get balance for this token')
        });
        this.loadingButton = true;
        await this.checkAllowance(val.address,'sell');
        this.loadingButton = false;
      }
    },
    form: {
      handler(val) {
        if (this.disableWatcher) return;
        if (!val.sellAmount && !val.buyAmount) return;
        if (this.paymentAmountDebounceTimeoutId !== null) {
          clearTimeout(this.paymentAmountDebounceTimeoutId);
        }
        this.$router.replace({ query: { ...this.$route.query, sell: val.sellCurrency.token }}).catch(() => {})
        this.paymentAmountDebounceTimeoutId = setTimeout(async () => {
          if (this.isSell) this.getBuy = true;
          this.paymentAmountDebounceTimeoutId = null;
          this.isProcessing = true;
          api.post('info-swap', {
            "sell_token": val.sellCurrency.token,
            "buy_token": 'CVL_PRESALE',
            "sell_amount": this.activeInput == 'sell' ? val.sellAmount : 0,
            "buy_amount": this.activeInput == 'buy' ? val.buyAmount : 0,
            "sell_is_active": this.isSell
          }).then(({data}) => {
            this.isProcessing = false;
            this.buyAmountError = [];
            if (data.error) {
              this.buyAmountError.push(data.error.message);
            }
            this.disableWatcher = true;
            setTimeout(() => {
              this.disableWatcher = false; // Disable watch during set of amount
            },200)
            this.form.buyAmount = data.buy;
            this.form.sellAmount = data.sell;
            this.commissionValues = data.commissions;
            if(!this.commissionToken.token) {
              this.commissionToken = this.commissionTokens[0];
            }
            this.$refs.form.validate();
          });
        }, 400);
      },
      deep: true
    },
    showModal(val) {
      if(!val) {
        this.stopTimer();
      }
    }
  },
  async mounted() {
    let buyItem = {};
    let sellItem = {};
    if(this.$route.query.amount) {
      this.form.buyAmount = Number(this.$route.query.amount);
    }
    if(this.$route.query.uuid) {
      this.uuid = this.$route.query.uuid;
      this.sign = this.$route.query.sign;
    }
    if(this.$route.query.sell) {
      sellItem = this.tokens.find(item => item.token === this.$route.query.sell);
      if(sellItem.token && sellItem.token != buyItem.token) this.form.sellCurrency = sellItem;
    }
    else {
      if(buyItem.id != this.tokens[1].id) this.form.sellCurrency = this.tokens[1];
      else this.form.sellCurrency = this.tokens[2];
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
