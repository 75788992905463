import store from ".";
import router from "../router";
import { api } from "/src/plugins/axios";
export default {
    namespaced: true,
    state: {
        user: null,
        tokens: []
    },
    getters: {
        getUser: (state) => {return state.user},
        getTokens: (state) => {return state.tokens}
    },
    actions: {
        async setUserAndTokens({ commit }, data) {
            commit('SET_USER', data.user);
            commit('SET_TOKENS', data.tokens);
        },
        async getUserAndTokens({ commit, state }, refresh = false) {
            if(!state.user || refresh) {
                await api.get(process.env.VUE_APP_BOT_URL+'get-balances').then(({data}) => {
                    commit('SET_USER', data.user);
                    commit('SET_TOKENS', data.tokens);
                }).catch(err => {
                    if(err.response?.status == 422) {
                        localStorage.removeItem('access_token');
                        store.commit('SET_PROFILE',null)
                        router.push('/signin');
                    }
                })
            }
        },
    },
    mutations: {
        SET_USER(state,user) {
            state.user = user;
        },
        SET_TOKENS(state,tokens) {
            state.tokens = tokens;
        },
    }
}