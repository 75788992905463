<template>
  <v-tooltip :max-width="width" top>
    <template v-slot:activator="{ on, attrs }">
      <svg v-if="type === 'question'" v-on="on" v-bind="attrs" class="tooltip-icon" width="18" height="18"
           viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" fill="#A7B0A9"/>
        <path d="M7.8892 10.8622H9.07173V10.7699C9.07884 10.017 9.32386 9.67259 9.9027 9.31747C10.5561 8.92685 10.9645 8.39418 10.9645 7.58097C10.9645 6.38068 10.0305 5.62784 8.67401 5.62784C7.43111 5.62784 6.42259 6.31676 6.38707 7.66619H7.65128C7.68324 6.99858 8.16619 6.67898 8.6669 6.67898C9.21023 6.67898 9.64702 7.04119 9.64702 7.59872C9.64702 8.09588 9.31676 8.43679 8.88707 8.70668C8.25852 9.0973 7.89631 9.49148 7.8892 10.7699V10.8622ZM8.51065 13.0781C8.92969 13.0781 9.28835 12.7301 9.2919 12.2969C9.28835 11.8707 8.92969 11.5227 8.51065 11.5227C8.07741 11.5227 7.72585 11.8707 7.7294 12.2969C7.72585 12.7301 8.07741 13.0781 8.51065 13.0781Z"/>
      </svg>
      <svg v-else v-on="on" v-bind="attrs" class="tooltip-icon" width="18" height="18" viewBox="0 0 18 18" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" fill="#A7B0A9"/>
        <path d="M9.6907 5.72727H8.31641L8.43004 10.8622H9.57351L9.6907 5.72727ZM9.00178 13.0781C9.42081 13.0781 9.77947 12.7301 9.78303 12.2969C9.77947 11.8707 9.42081 11.5227 9.00178 11.5227C8.56854 11.5227 8.21697 11.8707 8.22053 12.2969C8.21697 12.7301 8.56854 13.0781 9.00178 13.0781Z"/>
      </svg>
    </template>
    <span><slot/></span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'InfoTooltip',
  props: {
    width: {
      type: Number,
      default: 200
    },
    type: {
      type: String,
      default: 'question'
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-icon {
  cursor: default;

  circle {
    transition: .3s;
  }

  path {
    fill: $grey-darker;
  }

  &:hover circle {
    fill: #48F2BB;
  }
}
</style>
